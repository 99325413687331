import { forwardRef } from 'react'
import classnames from 'clsx'
import { StyledSelect } from './style'
import Feedback from '../feedback'
import { IInputProps } from '../types'

interface IProps extends IInputProps {
  children: React.ReactNode
  style?: React.CSSProperties | undefined
}

const Select = forwardRef<HTMLSelectElement, IProps>(
  (
    {
      className,
      disabled,
      state,
      feedbackText,
      id,
      name,
      value,
      onChange,
      onBlur,
      onClick,
      children,
      showState,
      showErrorOnly = true,
      width,
      height,
      style,
      customStyle,
      ...restProps
    },
    ref,
  ) => {
    return (
      <>
        <StyledSelect
          className={classnames(className, 'custom-select')}
          ref={ref}
          id={id}
          name={name}
          value={value}
          onChange={onChange}
          onClick={onClick}
          onBlur={onBlur}
          style={style}
          $state={state}
          $showState={showState}
          $showErrorOnly={showErrorOnly}
          $width={width}
          $height={height}
          $customStyle={customStyle}
          disabled={disabled}
          {...restProps}
        >
          {children}
        </StyledSelect>
        {feedbackText && showState && (
          <Feedback
            state={state}
            showState={showState}
            showErrorOnly={showErrorOnly}
          >
            {feedbackText}
          </Feedback>
        )}
      </>
    )
  },
)

Select.displayName = 'Select'

export default Select
