import { useLocation } from 'react-router-dom'
import { useMemo, useState } from 'react'

export const useHeaderTitle = () => {
  const location = useLocation()

  const headerTitle = useMemo(() => {
    if (location.pathname.includes('/board')) {
      return '문의게시판'
    }

    return '커뮤니티'
  }, [location.pathname])

  return headerTitle
}

export const useFormHandler = () => {
  const [formData, setFormData] = useState<{ [key: string]: any }>({
    title: '',
    content: '',
  })

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target
    setFormData((prev) => ({ ...prev, [name]: value })) // 기존 데이터 유지 및 새로운 키 추가
  }

  const handleSubmit = async () => {
    console.log('Final form data:', formData)
    // API 호출 등 처리
  }

  return { formData, handleChange, handleSubmit }
}

export const useBadge = () => {
  const mapping: { [key: string]: string } = {
    '00': '기타',
    '01': '잡담',
    '02': '질문',
  }

  return (code: string): string => {
    return mapping[code] || '기타'
  }
}
