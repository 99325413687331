import { Badge, Button, Input, Select, Table } from '@/components'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAxios } from '@/context/axiosContext'
import Pagination from '../_components/pagination'
import useFormatDate from '@/hooks/useFormatDate'
import { BoardListItem, CommentListItem } from '../boardItem'
import { useBadge } from '@/hooks/useBoard'

type TInput = HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement

interface GetBoardData {
  srchOpt?: string //검색옵션
  srchStr?: string //검색어
  page?: number //페이지
  bbsTy: string //게시판타입 (01:자유게시판)
  bbsSj: string //게시판말머리(01:잡담)
}

export const BoardList = () => {
  const { formatDate } = useFormatDate()
  const getBadgeLabel = useBadge()
  const navigate = useNavigate()
  const axios = useAxios()
  const [boardList, setBoardList] = useState<BoardListItem[]>([])
  const [totalCount, setTotalCount] = useState(0) //페이지 count
  const [page, setPage] = useState(1)
  const [bbsTy, setBbsTY] = useState('01')
  const [bbsSj, setBbsSj] = useState('')
  const [srchData, setSrchData] = useState({
    srchOpt: '01',
    srchStr: '',
  })

  const { pathname } = useLocation()
  const pathName = pathname.split('/')[1] // 예: '/type1/list' -> 'type1'

  // API 호출을 위한 useEffect 훅
  useEffect(() => {
    // API 호출 함수
    getBoardList() // 컴포넌트 마운트 시 API 호출
  }, [page, pathname]) // 빈 배열을 넣으면 처음 한 번만 호출됨

  const handlePageChange = (page: number) => {
    setPage(page)
    setBbsTY(bbsTy)
  }

  const handleSearch = (e: React.ChangeEvent<TInput>) => {
    const { name, value } = e.target as HTMLInputElement | HTMLTextAreaElement // 타입 캐스팅
    setSrchData((prev) => ({ ...prev, [name]: value }))
  }

  //글상세보기
  const handleDetailView = (nttSn: number) => {
    navigate(`/${pathName}/detail?NTT_SN=${nttSn}`)
  }
  //boardList
  const getBoardList = async () => {
    try {
      const data: GetBoardData = {
        srchOpt: srchData.srchOpt,
        srchStr: srchData.srchStr,
        page: page,
        bbsTy: bbsTy,
        bbsSj: bbsSj,
      }

      const response = await axios.get(`/${pathName}/list`, {
        headers: { 'Cache-Control': 'no-cache' },
        params: { ...data },
      })

      const { boardList, pageCnt, commentListCnt } = response.data

      console.log('boardList'), boardList
      console.log('commentListCnt', commentListCnt)
      // 응답 데이터가 배열인지 확인하고 상태 업데이트
      if (Array.isArray(boardList)) {
        setBoardList(boardList)
      }

      if (pageCnt > 0) {
        setTotalCount(pageCnt)
      }
    } catch (err: any) {
      console.error('Error fetching boardList:', err)
    }
  }

  console.log('boardList', boardList)

  const commonStyle = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textAlign: 'center' as const,
  }

  return (
    <>
      <div>
        <Table
          hover={true}
          bordered={true}
          style={{ width: '100%', tableLayout: 'auto', overflow: 'hidden' }}
        >
          <thead>
            {['NO.', '제목', '작성자', '작성일', '조회수'].map(
              (header, index) => (
                <th key={index} scope="col" style={commonStyle}>
                  {header}
                </th>
              ),
            )}
          </thead>
          <tbody>
            {boardList.length > 0 ? (
              boardList.map((item, index) => (
                <tr key={item.NTT_SN}>
                  <td style={{ textAlign: 'center', width:'30px' }}>{item.NTT_SN}</td>
                  <td
                    style={{
                      textAlign: 'left',
                      width: 'auto',
                      cursor: 'pointer',
                      maxWidth: '200px', // 최대 너비 설정
                      overflow: 'hidden', // 넘치는 텍스트 숨기기
                      whiteSpace: 'nowrap', // 텍스트를 한 줄로 유지
                      textOverflow: 'ellipsis', // 말줄임표로 표시
                    }}
                    onClick={() => handleDetailView(item.NTT_SN)}
                  >
                    <Badge color="secondary">
                      {getBadgeLabel(item.BBS_SJ)}
                    </Badge>
                    &nbsp;&nbsp;
                    {item.NTT_SJ}
                    {item.ANSWER_CNT ? ` [${item.ANSWER_CNT}]` : ''}
                  </td>
                  <td style={{ textAlign: 'center', width:'100px' }}>{item.NICK_NAME}</td>
                  <td style={{ textAlign: 'center', width:'50px' }}>
                    {formatDate(item.REG_DATE)}
                  </td>
                  <td style={{ textAlign: 'center', width:'40px'}}>{item.READNG_CNT}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={5} style={{ textAlign: 'center' }}>
                  아직 등록된 문의가 없습니다. 첫 번째로 문의를 남겨보세요!
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        <div style={{ display: 'flex' }}>
          <div style={{ marginLeft: 'auto' }}>
            <div style={{ display: 'flex', gap: '5px' }}>
              <Select
                id="srchOpt"
                name="srchOpt"
                width="100px"
                value={srchData.srchOpt}
                onChange={handleSearch}
              >
                <option value="01">제목</option>
                <option value="02">작성자</option>
              </Select>
              <Input
                id="srchStr"
                name="srchStr"
                placeholder="검색어를 입력하세요."
                width="300px"
                value={srchData.srchStr}
                onChange={handleSearch}
              />
              <Button color="neverbe" onClick={getBoardList}>
                조회
              </Button>
            </div>
          </div>
        </div>
      </div>
      {totalCount > 0 && (
        <Pagination totalCount={totalCount} onPageChange={handlePageChange} />
      )}
    </>
  )
}
