import { useAppSelector } from '@/redux/hooks'
import { useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { InsertForm } from './insertForm'
import { useAxios } from '@/context/axiosContext'

type TInput = HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
type FormData = {
  nttSj: string
  nttCn: string
  bbsSj: string
  guestName?: string
  guestEml?: string
  guestInsertCheck?:boolean
};

export const InsertGuest = () => {
  const axios = useAxios()
  const navigate = useNavigate()
  const [show, setShow] = useState(false)
  const [showPolicyPopup, setShowPolicyPopup] = useState<boolean>(false); 
  const [modalMessage, setModalMessage] = useState('') // Modal 메시지 상태
  const { isAuthenticated, user } = useAppSelector((state) => state.auth)
  const [showSaveButton, setShowSaveButton] = useState(true) // true: 저장/취소 || false : 닫기
  const [moveList, setMoveList] = useState(false) // true: 저장/취소 || false : 닫기
  const { pathname } = useLocation()
  const [error, setError] = useState<string | null>(null) // 에러 상태
  const pathName = pathname.split('/')[1] // 예: '/type1/list' -> 'type1'
  const [guestInsertCheck, setGuestInsertCheck] = useState(false)
  const [formData, setFormData] = useState({
    cmpnyCd: '001',
    bbsTy: '01',
    nttSj: '',
    nttCn: '',
    bbsSj: '',
    cstId: isAuthenticated ? user?.cstId : '',
    guestName: '',
    //guestMblNo: '',
    guestEml:'',
    guestInsertCheck:false
  })
  
  const [loading, setLoading] = useState(false) // 로딩 상태
  const fileInputRef = useRef<HTMLInputElement | null>(null)

  // const isValidEmailDomain = (email:string) => {
    
  //   const allowedDomains = ["naver.com", "gmail.com","naver.com","gmail.com","hanmail.net", 
  // "kakao.com", "daum.net", "hotmail.com", "yahoo.co.kr"]; // 허용된 이메일 도메인 목록
  //   const domain = email.split("@")[1];
  
  //   return allowedDomains.includes(domain);
  // };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault(); // 기본 폼 제출 동작 방지
    // 이메일 유효성 검사 추가
    const value = formData.guestEml; // 이메일 필드 값 가져오기
  
    // 필수 입력 필드와 메시지 매핑
    const requiredFields: { field: keyof FormData; message: string }[] = [
      { field: "bbsSj", message: "말머리를 선택하세요." },
      { field: "nttSj", message: "제목을 입력하세요." },
      { field: "nttCn", message: "내용을 입력하세요." },
      { field: "guestName", message: "이름(작성자명)을 입력하세요." },
      { field: "guestEml", message: "이메일을 입력하세요." },
      { field: "guestInsertCheck", message: "[필수] 개인정보 수집 및 이용 동의가 필요합니다." },
    ];

    // 필수 입력값 확인
    for (const { field, message } of requiredFields) {
      if (!formData[field]) {
        setModalMessage(message);
        setShow(true); // 모달 표시
        setShowSaveButton(false);
        return;
      }
    }
    // 이메일 유효성 검사
    if (!value.includes('@')) {
      setModalMessage('유효하지 않은 이메일 입니다. 올바른 이메일을 입력해 주세요.');
      setShow(true); // 모달 표시
      setShowSaveButton(false);
      return; // 유효하지 않은 경우, 이후 로직 실행 중단
    } 
  
    setModalMessage('저장하시겠습니까?')
    setShow(true)
    setShowSaveButton(true)
  }

  const handleChange = (e: React.ChangeEvent<TInput>) => {
    const { name, value } = e.target as HTMLInputElement | HTMLTextAreaElement // 타입 캐스팅
    setFormData((prev) => ({ ...prev, [name]: value }))
  }

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target
    setFormData((prevState) => ({
      ...prevState,
      [name]: checked,
    }))
  }

  const handleList = () => {
    setModalMessage('입력을 취소 하시곘습니까?')
    setShow(true)
    setShowSaveButton(true)
    setMoveList(true)
  }

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click() // 숨겨진 파일 입력창 열기
    }
  }

  const openPrivacyPolicy = () => {
    setShowPolicyPopup(true)
  }

  const handleModalAction = async (action: 'confirm' | 'cancel') => {
    if (action === 'confirm') {
      setShow(false)
      setLoading(true) // 로딩 시작

      if (!formData.nttSj || !formData.nttCn) {
        setModalMessage('내용을 입력하세요.')
        setShow(true) // 모달 표시
        setShowSaveButton(false)
        return
      }

      try {
        const response = await axios.post(`/${pathName}/insert`, formData) // POST 요청
        navigate(0);
      } catch (err: any) {
        setError(
          err.response?.data?.message || '알 수 없는 오류가 발생했습니다.',
        )
      } finally {
        setLoading(false) // 로딩 종료
      }
    } else if (action === 'cancel') {
      setShow(false) // 모달 닫기
    }
  }

  return (
    <>
      <InsertForm
        formData={formData}
        pathName={pathName}
        show={show}
        modalMessage={modalMessage}
        showSaveButton={showSaveButton}
        moveList={moveList}
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        handleCheckboxChange={handleCheckboxChange}
        handleButtonClick={handleButtonClick}
        handleModalAction={handleModalAction}
        handleList={handleList}
        openPrivacyPolicy={openPrivacyPolicy}
        showPolicyPopup={showPolicyPopup}
        setShowPolicyPopup={setShowPolicyPopup}
        setShow={setShow}
        navigate={navigate}
        isAuthenticated={isAuthenticated}
      />
    </>
  )
}
