import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  Radio,
  Select,
} from '@/components'
import { ChangeEvent, useState } from 'react'
import { X } from 'react-feather'
import {
  StyledClose,
  StyledGroup,
  StyledLabel,
  StyledTitle,
  ModalStyleWrapper,
} from './style'
import styled from 'styled-components'
import { FlexWrapper } from '@/components/layout/flex/flexWrapper'
import { regions } from '@/constants/regions'
import { ReqGolfPriceData } from '@/components/main/golfSearch/mainGolfSearchFilter'

interface IProps {
  show: boolean
  onClose: () => void
  applyFilters: (filters: ReqGolfPriceData) => void
}

export const SearchFilterModal = ({ show, onClose, applyFilters }: IProps) => {
  const [values, setValues] = useState({
    region: '',
    greenFeeMin: '',
    greenFeeMax: '',
    dayType: 'weekday', // default: 평일
    searchKeyword: '',
  })

  const changeHandler = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>,
  ) => {
    setValues((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }))
  }

  const submitHandler = () => {
    const filters: ReqGolfPriceData = {
      areaCd: values.region || null,
      minPrice: values.greenFeeMin ? Number(values.greenFeeMin) : null,
      maxPrice: values.greenFeeMax ? Number(values.greenFeeMax) : null,
      bizNm: values.searchKeyword || null,
      dayType: values.dayType || null,
    }
    applyFilters(filters)
    onClose()
  }

  return (
    <ModalStyleWrapper>
      <Modal
        show={show}
        centered
        onClose={onClose}
        closeOnBackdropClick={false}
      >
        <ModalBody>
          <StyledTitle>검색 필터</StyledTitle>
          <StyledClose onClose={onClose}>
            <X />
          </StyledClose>
          <form>
            <StyledGroup mt={20}>
              <StyledLabel htmlFor="searchKeyword">골프장</StyledLabel>
              <Input
                id="searchKeyword"
                name="searchKeyword"
                type="text"
                placeholder="골프장명을 입력해 주세요."
                value={values.searchKeyword}
                onChange={changeHandler}
              />
            </StyledGroup>
            <StyledGroup>
              <StyledLabel htmlFor="region">지역</StyledLabel>
              <Select
                id="region"
                name="region"
                value={values.region}
                onChange={changeHandler}
              >
                {regions.map((item) => (
                  <option key={item.code} value={item.code}>
                    {item.name}
                  </option>
                ))}
              </Select>
            </StyledGroup>

            <StyledGroup mt={20}>
              <StyledLabel>금액</StyledLabel>
              <InputRow>
                <Input
                  id="greenFeeMin"
                  name="greenFeeMin"
                  type="number"
                  placeholder="최소 금액"
                  value={values.greenFeeMin}
                  onChange={changeHandler}
                />
                <span>~</span>
                <Input
                  id="greenFeeMax"
                  name="greenFeeMax"
                  type="number"
                  placeholder="최대 금액"
                  value={values.greenFeeMax}
                  onChange={changeHandler}
                />
              </InputRow>
            </StyledGroup>

            <StyledGroup mt={20}>
              <StyledLabel>요금 구분</StyledLabel>
              <FlexWrapper>
                <Radio
                  id="weekday"
                  name="dayType"
                  value="weekday"
                  checked={values.dayType === 'weekday'}
                  onChange={changeHandler}
                  label="평일"
                />
                <Radio
                  id="weekend"
                  name="dayType"
                  value="weekend"
                  checked={values.dayType === 'weekend'}
                  onChange={changeHandler}
                  label="주말"
                />
              </FlexWrapper>
            </StyledGroup>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button onClick={submitHandler} mr="5px" color="neverbe">
            검색
          </Button>
          <Button color="secondary" onClick={onClose}>
            닫기
          </Button>
        </ModalFooter>
      </Modal>
    </ModalStyleWrapper>
  )
}

const InputRow = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  input {
    width: 100%;
  }

  span {
    margin: 0 5px;
  }
`
