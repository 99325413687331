import styled from 'styled-components'

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  justifyContent?: string
  alignItems?: string
  flexDirection?: string
  gap?: string
}

const StyledFlexWrapper = styled.div<IProps>`
  display: flex;
  justify-content: ${(props) => props.justifyContent || 'flex-start'};
  align-items: ${(props) => props.alignItems || 'center'};
  flex-direction: ${(props) => props.flexDirection || 'row'};
  gap: ${(props) => props.gap || '0'};
`

export const FlexWrapper: React.FC<IProps> = ({ children, ...props }) => {
  return <StyledFlexWrapper {...props}>{children}</StyledFlexWrapper>
}
