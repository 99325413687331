import Button from '@/components/ui/button/button'
import Modal from '@/components/ui/modal/modal'
import ModalBody from '@/components/ui/modal/modal-body'
import ModalFooter from '@/components/ui/modal/modal-footer'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAppSelector } from '@/redux/hooks'

export const BoardHeader = ({ title }: { title: string }) => {
  const [show, setShow] = useState(false)
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { isAuthenticated } = useAppSelector((state) => state.auth)
  const isWritePage =
    location.pathname.endsWith('/write') ||
    location.pathname.includes('/detail')

  useEffect(() => {
    if (show) {
      document.body.classList.add('doar-modal-open')
    } else {
      document.body.classList.remove('doar-modal-open')
    }
    return () => {
      document.body.classList.remove('doar-modal-open')
    }
  }, [show])

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        marginBottom: '10px',
        alignItems: 'center',
        marginTop: '50px',
      }}
    >
      <div
        style={{ textAlign: 'center', fontSize: '20px', cursor: 'pointer' }}
        onClick={() => navigate(0)}
      >
        {title}
      </div>
      {/* <>
        <Modal show={show} onClose={() => setShow(false)} size="sm">
          <ModalBody>
            <p>로그인 후에 글 쓰기가 가능합니다.</p>
          </ModalBody>
          <ModalFooter>
            <Button color="neverbe" onClick={() => navigate('/login')}>
              로그인
            </Button>
            <Button color="neverbe" onClick={() => setShow(false)}>
              닫기
            </Button>
          </ModalFooter>
        </Modal>
      </> */}
      {!isWritePage && (
        <Button color="neverbe" onClick={() => navigate(`${pathname}/write`)}>
          글쓰기
        </Button>
      )}
    </div>
  )
}
