import { useDispatch } from 'react-redux'
import { setGraphData } from '@/redux/slices/golfSearchSlice'
import { useAxios } from '@/context/axiosContext'
import { useSelector } from 'react-redux'
import { RootState } from '@/redux/store'
import { useState, useEffect } from 'react'

interface GolfPriceItem {
  bizCd: string
  bizNm: string // 골프장명
  areaNm: string // 지역명
  n01: number // 비회원 주중 요금
  n02: number // 비회원 주말 요금
  m01: number // 회원 주중 요금
  m02: number // 회원 주말 요금
  mshipYn: string // 회원제 여부
}

interface MonthData {
  n01: number
  n02: number
  m01: number
  m02: number
  month: string
  year: string
}

interface GolfPriceResponse {
  listData: GolfPriceItem[] // 골프장 요금 목록
  monthData: MonthData[] // 월별 요금 데이터
}

const generateMonths = (): string[] => {
  const monthsArr: string[] = []
  const currentDate = new Date()
  for (let i = 0; i < 12; i++) {
    const prevMonth = new Date(currentDate)
    prevMonth.setMonth(prevMonth.getMonth() - i)
    monthsArr.unshift(
      `${prevMonth.getFullYear().toString().slice(-2)}-${(
        prevMonth.getMonth() + 1
      )
        .toString()
        .padStart(2, '0')}`,
    )
  }
  return monthsArr
}

const mapMonthDataToGraph = (
  monthData: MonthData[],
  monthsArr: string[],
): number[][] => {
  const updatedGraphData = [
    new Array(12).fill(0), // n01
    new Array(12).fill(0), // n02
    new Array(12).fill(0), // m01
    new Array(12).fill(0), // m02
  ]

  monthData.forEach((item) => {
    //YY-MM
    const formattedMonth = `${item.year.slice(-2)}-${item.month.padStart(2, '0')}`
    const monthIndex = monthsArr.findIndex((month) => month === formattedMonth)

    if (monthIndex >= 0 && monthIndex < 12) {
      updatedGraphData[0][monthIndex] = item.n01
      updatedGraphData[1][monthIndex] = item.n02
      updatedGraphData[2][monthIndex] = item.m01
      updatedGraphData[3][monthIndex] = item.m02
    }
  })

  return updatedGraphData
}

const normalizeKey = <T, K extends keyof T>(
  data: T[],
  key: K,
  defaultValue: T[K],
): T[] => {
  return data.map((item) => ({
    ...item,
    [key]: item[key] === null ? defaultValue : item[key],
  }))
}

/**
 * 회원제여부 체크
 * 회원제여부 null이면 회원가격 여부가 있을 경우 Y, 없으면 N으로 표기
 */
const checkMembershipYn = (data: GolfPriceItem[]): GolfPriceItem[] => {
  return data.map((item) => {
    const m01Value = Number(item.m01)
    const m02Value = Number(item.m02)
    const mshipYn = item.mshipYn ?? (m01Value > 0 || m02Value > 0 ? 'Y' : 'N')
    return { ...item, mshipYn }
  })
}

export const useGolfSearchData = () => {
  const dispatch = useDispatch()
  const axios = useAxios()

  const searchParams = useSelector(
    (state: RootState) => state.golfSearch.searchParams,
  )

  const [data, setData] = useState<GolfPriceItem[]>([])

  const getGolfPriceList = async () => {
    try {
      const response = await axios.get<GolfPriceResponse>('/golf/list', {
        params: searchParams,
      })

      const listData = response.data.listData.map((item) => ({
        bizCd: item.bizCd,
        bizNm: item.bizNm,
        areaNm: item.areaNm,
        n01: item.n01,
        n02: item.n02,
        m01: item.m01,
        m02: item.m02,
        mshipYn: item.mshipYn,
      }))

      const normalizedData = checkMembershipYn(listData)
      // const refineData =
      setData(normalizedData)

      const monthData = response.data.monthData
      const monthsArr = generateMonths()
      const updatedGraphData = mapMonthDataToGraph(monthData, monthsArr)

      dispatch(setGraphData(updatedGraphData))
    } catch (error) {
      console.error('Error fetching golf price list:', error)
    }
  }

  useEffect(() => {
    getGolfPriceList()
  }, [searchParams])

  return { data }
}
