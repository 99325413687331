import classnames from 'clsx'
import { CSSProperties, forwardRef } from 'react'
import Feedback from '../feedback'
import { ICustomInputProps } from '../types'
import { StyledInput } from './style'

interface IProps extends ICustomInputProps {
  type?: string
  feedbackTextAlign?: CSSProperties['textAlign']
}

const Input = forwardRef<HTMLInputElement, IProps>(
  (
    {
      className,
      type = 'text',
      disabled,
      state,
      feedbackText,
      id,
      name,
      onChange,
      onClick,
      onBlur,
      value,
      readonly,
      showState,
      showErrorOnly = true,
      width,
      height,
      customStyle,
      feedbackTextAlign = 'left',
      ...restProps
    },
    ref,
  ) => {
    return (
      <>
        <StyledInput
          type={type}
          disabled={disabled}
          ref={ref}
          className={classnames(className, 'form-control')}
          id={id}
          name={name}
          onChange={onChange}
          onClick={onClick}
          onBlur={onBlur}
          value={value}
          readOnly={readonly}
          $state={state}
          $showState={showState}
          $showErrorOnly={showErrorOnly}
          $width={width}
          $height={height}
          $customStyle={customStyle}
          {...restProps}
        />
        {feedbackText && showState && (
          <Feedback
            state={state}
            showState={showState}
            showErrorOnly={showErrorOnly}
            feedbackTextAlign={feedbackTextAlign}
          >
            {feedbackText}
          </Feedback>
        )}
      </>
    )
  },
)

Input.displayName = 'Input'

export default Input
