const numberUtils = {
  // 숫자에 천 단위 구분 기호(콤마) 추가
  formatNumberWithCommas: (
    number: number | string | null | undefined,
  ): string => {
    if (number === null || number === undefined) {
      return ''
    }

    const num = typeof number === 'string' ? parseFloat(number) : number

    if (isNaN(num)) {
      return String(number)
    }

    return num.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  },

  // 숫자를 통화 형식으로 포맷 (예: "1,234,567 원")
  formatCurrency: (number: number | null | undefined): string => {
    if (number === null || number === undefined) {
      return ''
    }
    return numberUtils.formatNumberWithCommas(number) + ' 원' // 통화 형식으로 반환
  },
}

export default numberUtils
