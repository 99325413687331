import { useCallback } from 'react'

const useFormatDate = () => {
  const formatDate = useCallback((dateTime: string): string => {
    console.log('dateTime', dateTime)

    const datePart = dateTime.substring(0, 8) // "YYYYMMDD"
    const timePart = dateTime.substring(8, 14) // "HHMMSS"

    // 날짜를 파싱
    const year = parseInt(datePart.substring(0, 4))
    const month = parseInt(datePart.substring(4, 6)) - 1 // 월은 0부터 시작
    const day = parseInt(datePart.substring(6, 8))
    const date = new Date(year, month, day)

    // 현재 날짜 생성
    const today = new Date()
    const isToday =
      today.getFullYear() === year &&
      today.getMonth() === month &&
      today.getDate() === day

    if (isToday) {
      // 오늘 날짜라면 HH:MM:SS 형식 반환
      const hour = timePart.substring(0, 2)
      const minute = timePart.substring(2, 4)
      const second = timePart.substring(4, 6)
      return `${hour}:${minute}:${second}`
    } else {
      // 오늘 날짜가 아니라면 YYYY.MM.DD 형식 반환
      const formattedDate = new Intl.DateTimeFormat('ko-KR', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }).format(date)

      // Intl.DateTimeFormat 결과에서 마지막 마침표 제거
      return formattedDate.endsWith('.')
        ? formattedDate.slice(0, -1).replace(/\s/g, '')
        : formattedDate.replace(/\s/g, '')
    }
  }, [])

  return { formatDate }
}

export default useFormatDate
