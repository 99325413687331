import React from 'react'
import useCounter from '../../hooks/sampleUseCounter'

const CounterComponent: React.FC = () => {
  const [count, increment, decrement, reset] = useCounter(10)

  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h2>Counter: {count}</h2>
      <button onClick={increment}>Increment</button>
      <button onClick={decrement}>Decrement</button>
      <button onClick={reset}>Reset</button>
    </div>
  )
}

export default CounterComponent
