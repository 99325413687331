import React from 'react'
import { useLocation } from 'react-router-dom'
import Header from '@/components/common/header'
import Footer from '@/components/common/footer'
import { routes } from '@/routes/routes'
import styled from 'styled-components'
import ScrollTopButton from '@/components/ui/scrollTop/scrollTop'

interface LayoutProps {
  children: React.ReactNode
}

const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  align-items: center;
`

const LayoutMain = styled.div<{ isFullWidth: boolean }>`
  width: ${(props) => (props.isFullWidth ? '100%' : '60%')};
  display: flex;
  flex-direction: column;
  flex: 1;

  @media (max-width: 768px) {
    width: 100%;
    padding: 0 16px;
  }
`

const HeaderContainer = styled.div`
  width: 100%;
  border-bottom: 1px solid #e0e0e0;
  z-index: 100;
  position: sticky;
  top: 0;
  background-color: #fff;
`

const MainContent = styled.main`
  flex: 1;
  padding-bottom: 50px;
`

const FooterContainer = styled.div`
  width: 100%;
  margin: 0 auto;
`

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const location = useLocation()
  const currentRoute = routes.find(
    (route) => route.path === location.pathname,
  ) || {
    hideHeader: false,
    hideFooter: false,
    isFullWidth: false,
  }

  const hideHeader = currentRoute?.hideHeader ?? false
  const hideFooter = currentRoute?.hideFooter ?? false
  const isFullWidth = currentRoute?.isFullWidth ?? false

  return (
    <LayoutContainer>
      {!hideHeader && (
        <HeaderContainer>
          <Header />
        </HeaderContainer>
      )}

      <LayoutMain isFullWidth={isFullWidth}>
        <MainContent>{children}</MainContent>
      </LayoutMain>

      {!hideFooter && (
        <FooterContainer>
          <Footer />
        </FooterContainer>
      )}
      <ScrollTopButton />
    </LayoutContainer>
  )
}

export default Layout
