import SignCompleteForm from '@/components/member/signup-complete'
import Content from '@/components/layout/content'
import VerifyContainer from '@/components/member/verify-account'
import React from 'react'
import {
  StyledImage,
  StyledImgText,
  StyledMedia,
  StyledMediaBody,
  StyledSignin,
} from '@/components/member/signup/style'

const CompleteMember = () => {
  return (
    <>
      <Content fullHeight align="center">
        <VerifyContainer />
      </Content>
    </>
  )
}

export default CompleteMember
