import { useAxios } from '@/context/axiosContext'
import { useAppDispatch } from '@/redux/hooks'
import { login } from '@/redux/slices/auth'
import { useNavigate } from 'react-router-dom'

export interface IFormValues {
  userId: string
  password: string
}

export const useLogin = () => {
  const axios = useAxios()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const handleLogin = async (data: IFormValues) => {
    const reqData: IFormValues = {
      userId: data.userId,
      password: data.password,
    }

    const response = await axios.post('/auth/login', reqData)
    if (response.data.common.resultCode === 'L0200') {
      console.log('Login Success', response.data.data)
      dispatch(login({ ...response.data.data }))
      navigate('/main')
    } else {
      alert(
        `${response.data.common.resultCode}\n${response.data.common.resultMsg}`,
      )
    }
  }

  return { handleLogin }
}
