import { useState } from 'react'

const useCopyToClipboard = (): [boolean, (text: string) => void] => {
  const [isCopied, setIsCopied] = useState(false)

  const copyToClipboard = (text: string) => {
    if (!navigator.clipboard) {
      console.error('copyToClipboard Error')
      return
    }

    navigator.clipboard
      .writeText(text)
      .then(() => {
        setIsCopied(true)
        setTimeout(() => setIsCopied(false), 2000) // 2초 후 상태 초기화
      })
      .catch((error) => {
        console.error('URL 복사 실패:', error)
        setIsCopied(false)
      })
  }

  return [isCopied, copyToClipboard]
}

export default useCopyToClipboard
