import { useAxios } from '@/context/axiosContext'
import { useState } from 'react'

export const NiceApiTest = () => {
  const [apiType, setApiType] = useState<string>('issue') // 기본적으로 issueToken으로 설정

  const axios = useAxios()

  const handleClick = (type: string) => {
    callNicePopup()
  }

  const callNicePopup = async () => {
    try {
      const response = await axios.post('/certification/nice-test', {})
      const { tokenVersionId, encData, integrityValue } = response.data

      console.log(response)
      const form = document.createElement('form')
      form.action = 'https://nice.checkplus.co.kr/CheckPlusSafeModel/service.cb'
      form.method = 'POST'

      const input1 = document.createElement('input')
      input1.type = 'hidden'
      input1.name = 'm'
      input1.value = 'service'
      form.appendChild(input1)

      const input2 = document.createElement('input')
      input2.type = 'hidden'
      input2.name = 'token_version_id'
      input2.value = tokenVersionId
      form.appendChild(input2)

      const input3 = document.createElement('input')
      input3.type = 'hidden'
      input3.name = 'enc_data'
      input3.value = encData
      form.appendChild(input3)

      const input4 = document.createElement('input')
      input4.type = 'hidden'
      input4.name = 'integrity_value'
      input4.value = integrityValue
      form.appendChild(input4)

      // document.body.appendChild(form)
      // form.submit()

      const popupWindow = window.open('', '_blank', 'width=800,height=600')
      if (popupWindow) {
        popupWindow.document.body.appendChild(form)
        form.submit()
      } else {
        console.error('팝업 창을 열 수 없습니다.')
      }

      console.log(response)
    } catch (err: any) {
      console.error('Error fetching:', err)
    }
  }

  return (
    <div>
      <h1>NiceApiTest</h1>
      <button onClick={() => handleClick('issue')}>issue Token</button>
      <button onClick={() => handleClick('revoke')}>revoke Token</button>
      <button onClick={() => handleClick('crypto')}>crypto Token</button>
    </div>
  )
}
