import { Button, Modal, ModalBody, ModalFooter } from "@/components"
import { useState } from "react"

interface PrivacyPolicyProps {
  showPolicyPopup: boolean
  onClose: () => void
}

export const PrivacyPolicy = ({ showPolicyPopup, onClose }: PrivacyPolicyProps) => {
    return (
        <Modal show={showPolicyPopup} onClose={onClose} size="md">
            <ModalBody>
                <PrivacyConsent/>
            </ModalBody>
            <ModalFooter>
                    <Button
                        color="neverbe"
                        onClick={onClose}
                    >
                        확인
                    </Button>
            </ModalFooter>
        </Modal>
    )
}

export const PrivacyConsent = () => {
    return (
      <div
        style={{
            width: "100%",
            maxWidth: "600px", // 최대 너비 설정
            margin: "0 auto", // 가운데 정렬
        }}
      >
        <h2 style={{ fontSize: "18px", marginBottom: "15px", color: "#333" }}>
        <strong>[필수] 개인정보 수집 및 이용 동의</strong>
        </h2>
        <p style={{ fontSize: "14px", color: "#555" }}>
          본 서비스를 통해 글을 작성하기 위해{" "}
          작성자의 <strong style={{ color: "#555" }}>이름(닉네임)</strong>과 <strong style={{ color: "#555" }}>이메일 주소</strong>를 수집합니다.
        </p>
        <p style={{ fontSize: "14px", color: "#555", fontStyle:'bold' }}>
        <strong style={{ color: "#555" }}>수집된 개인정보는 다음과 같은 목적을 위해 사용됩니다.</strong>
        </p>
        <div
          style={{
            fontSize: "14px",
            color: "#555",
            paddingLeft: "20px",
            marginBottom: "15px",
          }}
        >
          <li style={{ marginBottom: "8px" }}>글 작성 시 작성자 식별 (닉네임)</li>
          <li style={{ marginBottom: "8px" }}>문의나 답변을 위한 연락 (이메일)</li>
          <li style={{ marginBottom: "8px" }}>서비스 개선 및 회원 관리</li>
        </div>
        <p style={{ fontSize: "14px", color: "#555" }}><strong>수집되는 개인정보 항목</strong></p>
        <div
          style={{
            fontSize: "14px",
            color: "#555",
            paddingLeft: "20px",
            marginBottom: "15px",
          }}
        >
          <li style={{ marginBottom: "8px" }}>이름 (닉네임)</li>
          <li>이메일 주소</li>
        </div>
        <p style={{ fontSize: "14px", color: "#555" }}>
         개인정보는 글 작성과 관련된 서비스 제공에만 사용되며, 
          본인의 동의 없이 다른 목적으로 사용되지 않습니다.
        </p>
      </div>
    )
  }