import { Button } from '@/components'
import image from '@/assets/images/img17.png'
import { useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import {
  StyledWrap,
  StyledImg,
  StyledTitle,
  StyledDesc,
  StyledBtnWrap,
} from './style'

const VerifyContainer = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { data } = location.state || {} // 전달된 데이터 가져오기
  const isLogin = true
  const buttonHandler = (pathName: string) => {
    console.log('pathName:', pathName)
    if (isLogin) {
      switch (pathName) {
        case 'main':
          return navigate('/main')
        case 'login':
          return navigate('/login')
        default:
          return navigate('/main')
      }
    }
  }

  return (
    <StyledWrap>
      <StyledImg>
        <img src={image} alt="verify" />
      </StyledImg>
      <StyledTitle>회원가입이 완료되었습니다.</StyledTitle>
      <StyledDesc>
        {data} 고객님 정상적으로 회원가입이 완료되었습니다. 로그인 후 이용
        부탁드립니다.
      </StyledDesc>
      <StyledBtnWrap>
        <Button path="/" color="neverbe" onClick={() => buttonHandler('login')}>
          로그인하기
        </Button>
        <Button
          path="/"
          color="neverbe"
          variant="outlined"
          ml="5px"
          onClick={() => buttonHandler('main')}
        >
          메인으로
        </Button>
      </StyledBtnWrap>
    </StyledWrap>
  )
}

export default VerifyContainer
