import styled from 'styled-components'

export const MainGolfSearchNoData = () => {
  return (
    <MainGolfSearchNoDataWrap>
      <b>해당하는 결과가 없습니다.</b>
      <br />
      단어의 철자나 띄어쓰기가 정확한지 확인해보세요.
    </MainGolfSearchNoDataWrap>
  )
}

const MainGolfSearchNoDataWrap = styled.div`
  min-height: 369px;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
