export const regions = [
  { code: '', name: '전국' },
  { code: '11', name: '서울특별시' },
  { code: '21', name: '부산광역시' },
  { code: '22', name: '대구광역시' },
  { code: '23', name: '인천광역시' },
  { code: '24', name: '광주광역시' },
  { code: '25', name: '대전광역시' },
  { code: '26', name: '울산광역시' },
  { code: '29', name: '세종특별자치시' },
  { code: '31', name: '경기도' },
  { code: '32', name: '강원도' },
  { code: '33', name: '충청북도' },
  { code: '34', name: '충청남도' },
  { code: '35', name: '전라북도' },
  { code: '36', name: '전라남도' },
  { code: '37', name: '경상북도' },
  { code: '38', name: '경상남도' },
  { code: '39', name: '제주특별자치도' },
]
