import { Button, Col, FormGroup, Input, Label, Select } from '@/components'
import Radio from '@/components/forms/form-elements/radio'
import { FlexWrapper } from '@/components/layout/flex/flexWrapper'
import { useAxios } from '@/context/axiosContext'
import { usePostcodeSearch } from '@/hooks/usePostcodeSearch'
import { useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import {
  StyledBottomText,
  StyledDesc,
  StyledRequired,
  StyledTextUnderLine,
  StyledTitle,
  StyledWrap,
} from './style'
import commonUtils from '@/utils/commonUtils'

interface IFormValues {
  id: string
  password: string
  name: string
  last_name: string
}

interface PostData {
  cstId: string
  cmpnyCd: string
  joinDivCd: string
  snsId: string
  userId: string
  password: string
  password_confm?: string
  ci: string
  name: string
  nickName: string
  gender: string
  birth: string
  birthYy?: string
  birthMm?: string
  birthDd?: string
  email: string
  email1?: string
  email2?: string
  smsOptIn: string
  smsReceiveYn?: string
  jobCd: string
  homePostCd: string
  homeAddrNum?: string
  homeAddr: string
  homeDaddr: string
  homeArCd: string
  homeExcNo: string
  homeSubNo: string
  mnc: string
  mblNetCd: string
  mblExcNo: string
  mblSubNo: string
  department: string
  jbps: string
  lastLogin: string
  accessIp: string
  pwErrCount: string
  state: string
  withDrawDttm: string
  refreshToken: string
  regId: string
  updId: string
}

const SigninForm = () => {
  const navigate = useNavigate()
  const isLogin = true
  const axios = useAxios()
  const {
    register,
    handleSubmit,
    getValues,
    trigger,
    setValue,
    formState: { errors },
  } = useForm<PostData>({
    defaultValues: {
      //default
      gender: 'male',
    },
  })

  const [selectedEmail, setSelectedEmail] = useState<any>(null)
  const [postResponse, setPostResponse] = useState<any>(null)
  const [chkEmail, setChkEmail] = useState<any>(null)
  const [inputs, setInputs] = useState({
    mblExcNo: '',
    mblSubNo: '',
    homeExcNo: '',
    homeSubNo: '',
  })

  const fnChkDupUserId = () => {
    if (getValues('userId').length < 6) {
      alert('아이디를 6자리 이상 입력해주세요.')
      return
    }
    sendGetData(getValues('userId'))
  }

  const fnChkDupNickName = () => {
    sendGetData_nick(getValues('nickName'))
  }

  const { data: PostHomeData, handleOpenPopup: handleOpenPopup1 } =
    usePostcodeSearch() //자택주소검색 hook

  const onSubmit: SubmitHandler<PostData> = (data) => {
    console.log(JSON.stringify(data, null))
    sendPostData(data)
  }

  const [userId, setUsernId] = useState('')

  const handleKeyDown = (e: any) => {
    // 특수문자를 차단하는 정규식
    const invalidChars = /[^a-zA-Z0-9]/

    if (invalidChars.test(e.key)) {
      e.preventDefault()
    }
  }

  const handleInputChange = (e: any) => {
    setUsernId(e.target.value)
  }

  const selectHandler = (data: any) => {
    console.log('data:', data)
    let selectedValue = data.target.value

    if (selectedValue === '') {
      selectedValue = ''
    }
    setValue('email2', selectedValue)
  }

  // 아이디 중복 체크 GET 요청
  const sendGetData = async (chkId: string) => {
    try {
      const tmp_data = {
        userId: chkId,
      }
      const response = await axios.get('/member/checkUserId', {
        params: { ...tmp_data },
      }) // GET 요청
      if ('N' == response.data) {
        alert('이미 사용중이 아이디 입니다.')
      } else {
        alert('사용가능한 아이디 입니다.')
      }
    } catch (error) {
      console.error('Error during GET request:', error)
    }
  }

  // 닉네임 중복 체크 GET 요청
  const sendGetData_nick = async (chkNickName: string) => {
    try {
      const tmp_data = {
        nickName: chkNickName,
      }
      const response = await axios.get('/member/checkNickName', {
        params: { ...tmp_data },
      }) // GET 요청
      if ('N' == response.data) {
        alert('이미 사용중이 닉네임 입니다.')
      } else {
        alert('사용가능한 닉네임 입니다.')
      }
    } catch (error) {
      console.error('Error during GET request:', error)
    }
  }

  //Email입력값에 @ 포함 여부 체크
  const validateEmail = (chkEmail: any) => {
    console.log('이메일체크 : ' + chkEmail.includes('@'))
    return chkEmail.includes('@') // '@' 포함 여부 체크
  }

  // POST 요청
  const sendPostData = async (data: PostData) => {
    try {
      // 이메일 유효성 체크 후 입력값에 @포함될 경우 삭제처리
      if (validateEmail(data.email2)) {
        data.email2 = data.email2?.replace('@', '')
      }

      data.birth = (data.birthYy || '') + data.birthMm + data.birthDd
      data.email = (data.email1 || '') + '@' + data.email2

      if ('male' == data.gender) {
        data.gender = 'M'
      } else if ('female' == data.gender) {
        data.gender = 'F'
      } else if ('none' == data.gender) {
        data.gender = ''
      }

      const response = await axios.post('/member/insertMemberInfo', data) // POST 요청
      setPostResponse(response.data) // 서버에서 받은 응답을 상태에 저장
      console.log('response data:', response.data)
      console.log('response data.cntResult:', response.data.cntResult)
      if ('S' == response.data.cntResult) {
        navigate('/member/completeMember', {
          state: { data: response.data.userName },
        })
      }
    } catch (error) {
      console.error('Error during POST request:', error)
    }
  }

  const buttonHandler = (pathName: string) => {
    console.log('pathName:', pathName)
    if (isLogin) {
      switch (pathName) {
        case 'complete':
          return
        case 'cancle':
          return navigate('/main')
        default:
          return navigate('/member')
      }
    }
  }

  const handleChangeNumChk = (e: any) => {
    const { name, value } = e.target

    // 숫자만 허용하는 정규식
    if (/^\d*$/.test(value)) {
      setInputs({
        ...inputs,
        [name]: value, // 변경된 input 값만 업데이트
      })
    }
  }
  ;``

  return (
    <StyledWrap>
      <StyledTitle>네버비 회원가입</StyledTitle>
      <StyledDesc>회원가입 - 정보입력 </StyledDesc>
      <form action="#" onSubmit={handleSubmit(onSubmit)} noValidate>
        <StyledTextUnderLine>
          회원 가입
          <p style={{ textAlign: 'right', fontSize: '12px' }}>
            <StyledRequired>*</StyledRequired> 필수입력값
          </p>
        </StyledTextUnderLine>
        <FormGroup mb="20px">
          <FlexWrapper>
            <div style={{ padding: '5px', width: '20%' }}>
              <Label display="block" mb="5px" htmlFor="userId">
                <StyledRequired>*</StyledRequired> 아이디
              </Label>
            </div>
            <div style={{ width: '60%' }}>
              <Input
                {...register('userId', { required: true })}
                id="userId"
                name="userId"
                type="text"
                placeholder="아이디를 입력해주세요."
                maxLength={16}
                minLength={6}
                value={userId}
                onKeyDown={handleKeyDown}
                onChange={handleInputChange}
              />
            </div>
            <div style={{ padding: '10px', width: '20%' }}>
              <Button
                color="dark"
                active
                width="100px"
                onClick={fnChkDupUserId}
              >
                중복확인
              </Button>
            </div>
          </FlexWrapper>
          <p style={{ color: 'blue', padding: '0 0 0 20%' }}>
            * 영문(대/소문자구분), 숫자 조합 등으로 6~14자로 입력해주세요.
          </p>
        </FormGroup>
        <FormGroup mb="20px">
          <FlexWrapper>
            <div style={{ padding: '5px', width: '20%' }}>
              <Label display="block" mb="5px" htmlFor="password">
                <StyledRequired>*</StyledRequired> 비밀번호
              </Label>
            </div>
            <div style={{ width: '60%' }}>
              <Input
                {...register('password', { required: true })}
                id="password"
                name="password"
                type="password"
                placeholder="비밀번호를 입력해주세요."
              />
            </div>
            <div style={{ width: '20%' }}></div>
          </FlexWrapper>
        </FormGroup>
        <FormGroup mb="20px">
          <FlexWrapper>
            <div style={{ padding: '5px', width: '20%' }}>
              <Label display="block" mb="5px" htmlFor="password_confm">
                <StyledRequired>*</StyledRequired> 비밀번호 확인
              </Label>
            </div>
            <div style={{ width: '60%' }}>
              <Input
                {...register('password_confm', {
                  required: true,
                  validate: (value) =>
                    value === getValues('password') ||
                    '비밀번호가 일치하지 않습니다.',
                  onBlur: () => trigger('password_confm'),
                })}
                id="password_confm"
                name="password_confm"
                type="password"
                placeholder="비밀번호 확인을 입력해주세요."
                feedbackText={errors?.password_confm?.message} // 에러 메시지 처리
                state={
                  commonUtils.hasKey(errors, 'password_confm')
                    ? 'error'
                    : 'success'
                } // 상태 처리
                showState={!!commonUtils.hasKey(errors, 'password_confm')} // 상태 노출 여부
              />
            </div>
            <div style={{ width: '20%' }}></div>
          </FlexWrapper>
        </FormGroup>

        <FormGroup mb="20px">
          <FlexWrapper>
            <div style={{ padding: '5px', width: '20%' }}>
              <Label display="block" mb="5px" htmlFor="name">
                <StyledRequired>*</StyledRequired> 이름
              </Label>
            </div>
            <div style={{ width: '60%' }}>
              <Input
                {...register('name', { required: true })}
                id="name"
                name="name"
                type="text"
                placeholder="이름을 입력해주세요."
                maxLength={20}
              />
            </div>
            <div style={{ width: '20%' }}></div>
          </FlexWrapper>
        </FormGroup>

        <FormGroup mb="20px">
          <FlexWrapper>
            <div style={{ padding: '5px', width: '20%' }}>
              <Label display="block" mb="5px" htmlFor="nickName">
                <StyledRequired>*</StyledRequired> 닉네임
              </Label>
            </div>
            <div style={{ width: '60%' }}>
              <Input
                {...register('nickName', { required: true })}
                id="nickName"
                name="nickName"
                type="text"
                placeholder="닉네임을 입력해주세요."
                maxLength={20}
              />
            </div>
            <div style={{ padding: '10px', width: '20%' }}>
              <Button
                color="dark"
                active
                width="100px"
                onClick={fnChkDupNickName}
              >
                중복확인
              </Button>
            </div>
          </FlexWrapper>
        </FormGroup>

        <FormGroup mb="20px">
          <FlexWrapper>
            <div style={{ padding: '5px', width: '20%' }}>
              <Label display="block" mb="5px" htmlFor="gender">
                <StyledRequired>*</StyledRequired> 성별
              </Label>
            </div>
            <div
              style={{
                width: '20%',
                alignItems: 'center',
                gap: '20px',
              }}
            >
              <Radio
                id="radio1"
                value={'male'}
                label="남성"
                {...register('gender')}
              ></Radio>
            </div>
            <div
              style={{
                width: '20%',
                alignItems: 'center',
                gap: '20px',
              }}
            >
              <Radio
                id="radio2"
                value={'female'}
                label="여성"
                {...register('gender')}
              ></Radio>
            </div>
            <div
              style={{
                width: '20%',

                alignItems: 'center',
                gap: '20px',
              }}
            >
              <Radio
                id="radio3"
                value={'none'}
                label="선택안함"
                {...register('gender')}
              ></Radio>
            </div>
          </FlexWrapper>
        </FormGroup>
        <FormGroup mb="20px">
          <FlexWrapper>
            <div style={{ padding: '5px', width: '20%' }}>
              <Label display="block" mb="5px" htmlFor="birth">
                <StyledRequired>*</StyledRequired> 생년월일
              </Label>
            </div>
            <div style={{ width: '18%' }}>
              <Input
                {...register('birthYy')}
                placeholder="YYYY"
                mb="10px"
                id="birthYy"
                name="birthYy"
                maxLength={4}
              />
            </div>
            <p style={{ marginBottom: '10px' }}>&nbsp;&nbsp;년&nbsp;&nbsp;</p>

            <div style={{ width: '18%' }}>
              <Input
                {...register('birthMm')}
                placeholder="MM"
                mb="10px"
                id="birthMm"
                name="birthMm"
                maxLength={2}
              />
            </div>
            <p style={{ marginBottom: '10px' }}>&nbsp;&nbsp;월&nbsp;&nbsp;</p>

            <div style={{ width: '18%' }}>
              <Input
                {...register('birthDd')}
                placeholder="DD"
                mb="10px"
                id="birthDd"
                name="birthDd"
                maxLength={2}
              />
            </div>
            <p style={{ marginBottom: '10px' }}>&nbsp;&nbsp;일</p>
            <div style={{ padding: '10px', width: '20%' }}></div>
          </FlexWrapper>
        </FormGroup>

        <FormGroup mb="20px">
          <FlexWrapper>
            <div style={{ padding: '5px', width: '20%' }}>
              <Label display="block" mb="5px" htmlFor="radio3">
                <StyledRequired>*</StyledRequired> SMS서비스
              </Label>
            </div>
            <div style={{ width: '15%' }}>
              <Radio
                {...register('smsOptIn', { required: true })}
                id="radio4"
                name="smsOptIn"
                label="수신합니다."
                checked
              ></Radio>
            </div>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <div style={{ width: '15%' }}>
              <Radio
                {...register('smsOptIn', { required: true })}
                id="radio5"
                name="smsOptIn"
                label="거부합니다."
              ></Radio>
            </div>
          </FlexWrapper>
        </FormGroup>

        <FormGroup mb="20px">
          <FlexWrapper>
            <div style={{ padding: '5px', width: '20%' }}>
              <Label display="block" mb="5px" htmlFor="mblNetCd">
                <StyledRequired>*</StyledRequired> 휴대폰
              </Label>
            </div>
            <div style={{ width: '14%' }}>
              <Select
                mb={'10px'}
                {...register('mblNetCd', { required: true })}
                id="mblNetCd"
                name="mblNetCd"
              >
                <option value="010">010</option>
                <option value="011">011</option>
                <option value="016">016</option>
                <option value="017">017</option>
                <option value="018">018</option>
                <option value="019">019</option>
              </Select>
            </div>
            <p style={{ marginBottom: '10px' }}>&nbsp;-&nbsp;</p>
            <div style={{ width: '21%' }}>
              <Input
                {...register('mblExcNo', { required: true })}
                mb="10px"
                id="mblExcNo"
                name="mblExcNo"
                maxLength={4}
                onChange={handleChangeNumChk}
                value={inputs.mblExcNo}
                placeholder="숫자만 입력해주세요."
              />
            </div>
            <p style={{ marginBottom: '10px' }}>&nbsp;-&nbsp;</p>
            <div style={{ width: '21%' }}>
              <Input
                {...register('mblSubNo', { required: true })}
                mb="10px"
                id="mblSubNo"
                name="mblSubNo"
                maxLength={4}
                onChange={handleChangeNumChk}
                value={inputs.mblSubNo}
                placeholder="숫자만 입력해주세요."
              />
            </div>
          </FlexWrapper>
        </FormGroup>
        <FormGroup mb="20px">
          <FlexWrapper>
            <div style={{ padding: '5px', width: '20%' }}>
              <Label display="block" mb="5px" htmlFor="homePostCd">
                <StyledRequired>*</StyledRequired> 자택 주소
              </Label>
            </div>
            <div style={{ width: '60%' }}>
              <Input
                {...register('homePostCd', { required: true })}
                id="homePostCd"
                name="homePostCd"
                type="text"
                placeholder="우편번호"
                maxLength={8}
                value={PostHomeData?.zonecode || ''}
              />
            </div>
            <div style={{ padding: '10px', width: '20%' }}>
              <Button
                color="secondary"
                variant="outlined"
                width="100px"
                onClick={handleOpenPopup1}
              >
                주소검색
              </Button>
            </div>
          </FlexWrapper>
          <FlexWrapper>
            <div style={{ padding: '5px', width: '20%' }}></div>
            <div style={{ width: '60%' }}>
              <Input
                {...register('homeAddr', { required: true })}
                mb="10px"
                placeholder="기본주소"
                id="homeAddr"
                name="homeAddr"
                value={PostHomeData?.address || ''}
              />
            </div>
            <div style={{ width: '20%' }}></div>
          </FlexWrapper>
          <FlexWrapper>
            <div style={{ padding: '5px', width: '20%' }}></div>
            <div style={{ width: '60%' }}>
              <Input
                {...register('homeDaddr', { required: true })}
                mb="10px"
                placeholder="상세주소"
                id="homeDaddr"
                name="homeDaddr"
              />
            </div>
            <div style={{ width: '20%' }}></div>
          </FlexWrapper>
        </FormGroup>

        <FormGroup mb="20px">
          <FlexWrapper>
            <div style={{ padding: '5px', width: '20%' }}>
              <Label display="block" mb="5px" htmlFor="homeArCd">
                자택 전화 번호
              </Label>
            </div>
            <div style={{ width: '14%' }}>
              <Select
                mb={'10px'}
                {...register('homeArCd')}
                id="homeArCd"
                name="homeArCd"
                width="150px"
              >
                <option value="02">02</option>
                <option value="031">031</option>
                <option value="032">032</option>
                <option value="033">033</option>
                <option value="041">041</option>
                <option value="042">042</option>
                <option value="043">043</option>
                <option value="051">051</option>
                <option value="052">052</option>
                <option value="053">053</option>
                <option value="054">054</option>
                <option value="055">055</option>
                <option value="061">061</option>
                <option value="062">062</option>
                <option value="063">063</option>
                <option value="064">064</option>
                <option value="070">070</option>
              </Select>
            </div>
            <p style={{ marginBottom: '10px' }}>&nbsp;-&nbsp;</p>
            <div style={{ width: '21%' }}>
              <Input
                {...register('homeExcNo')}
                mb="10px"
                id="homeExcNo"
                name="homeExcNo"
                maxLength={4}
                onChange={handleChangeNumChk}
                value={inputs.homeExcNo}
                placeholder="숫자만 입력해주세요."
              />
            </div>
            <p style={{ marginBottom: '10px' }}>&nbsp;-&nbsp;</p>
            <div style={{ width: '21%' }}>
              <Input
                {...register('homeSubNo')}
                mb="10px"
                id="homeSubNo"
                name="homeSubNo"
                maxLength={4}
                onChange={handleChangeNumChk}
                value={inputs.homeSubNo}
                placeholder="숫자만 입력해주세요."
              />
            </div>
          </FlexWrapper>
        </FormGroup>

        <FormGroup mb="20px">
          <FlexWrapper>
            <div style={{ padding: '5px', width: '20%' }}>
              <Label display="block" mb="5px" htmlFor="email1">
                이메일
              </Label>
            </div>
            <div style={{ width: '24%' }}>
              <Input
                {...register('email1', { required: true })}
                mb="10px"
                id="email1"
                name="email1"
              />
            </div>
            <p style={{ marginBottom: '10px' }}>&nbsp;@&nbsp;</p>
            <div style={{ width: '15%' }}>
              <Input
                {...register('email2', { required: true })}
                mb="10px"
                id="email2"
                name="email2"
              />
            </div>
            <div style={{ width: '20%' }}>
              <Col>
                <Select
                  mb={'10px'}
                  id="selEmail"
                  name="selEmail"
                  onChange={selectHandler}
                >
                  <option value="">직접입력</option>
                  <option value="naver.com">naver.com</option>
                  <option value="gmail.com">gmail.com</option>
                  <option value="hanmail.net">hanmail.net</option>
                  <option value="kakao.com">kakao.com</option>
                  <option value="daum.net">daum.net</option>
                  <option value="hotmail.com">hotmail.com</option>
                  <option value="yahoo.co.kr">yahoo.co.kr</option>
                </Select>
              </Col>
            </div>
          </FlexWrapper>
        </FormGroup>

        <FormGroup mb="20px">
          <FlexWrapper>
            <div style={{ padding: '5px', width: '20%' }}>
              <Label display="block" mb="5px" htmlFor="jobCd">
                직업
              </Label>
            </div>
            <div style={{ width: '60%' }}>
              <Select {...register('jobCd')} id="jobCd" name="jobCd">
                <option value="">직업을 선택해 주세요</option>
                <option value="01">교육</option>
                <option value="02">연구개발</option>
                <option value="03">법률</option>
                <option value="04">회계/세무</option>
                <option value="05">숙박/음식</option>
                <option value="06">오락/문화/스포츠</option>
                <option value="07">통신</option>
                <option value="08">운송/물류</option>
                <option value="09">건설</option>
                <option value="10">농업/임업/광업/수산업</option>
                <option value="11">방송/언론/광고</option>
                <option value="12">유통/무역</option>
                <option value="13">의약</option>
                <option value="14">의료기기</option>
                <option value="15">개인병원</option>
                <option value="16">종합병원</option>
                <option value="17">자영업</option>
                <option value="18">정부</option>
                <option value="19">비영리/사회복지/행정</option>
                <option value="20">제1금융</option>
                <option value="21">제2금융</option>
                <option value="22">제조업1(금속/제철)</option>
                <option value="23">제조업2(의복/섬유/목재)</option>
                <option value="24">제조업3(석유/화학/전기)</option>
                <option value="25">컴퓨터/인터넷</option>
                <option value="26">골프업계</option>
                <option value="27">무직</option>
                <option value="99">기타</option>
              </Select>
            </div>
            <div style={{ width: '20%' }}></div>
          </FlexWrapper>
        </FormGroup>
        <div style={{ padding: '30px 0 0 0', textAlign: 'center' }}>
          <FormGroup mb="20px" margin={'AlignCenter'}>
            <Button type="submit" color="neverbe" width="100px">
              확인
            </Button>
            &nbsp;&nbsp;&nbsp;
            <Button
              type="submit"
              color="neverbe"
              variant="outlined"
              width="100px"
              onClick={() => buttonHandler('cancle')}
            >
              취소
            </Button>
          </FormGroup>
        </div>
        <StyledBottomText></StyledBottomText>
      </form>
    </StyledWrap>
  )
}

export default SigninForm
