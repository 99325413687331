import { DAUM_POSTCODE_URL } from '@/utils/constants'
import { useState } from 'react'
import { useDaumPostcodePopup } from 'react-daum-postcode'

export interface DaumPostcodeData {
  zonecode: string // 국가기초구역번호. 2015년 8월 1일부터 시행될 새 우편번호.
  address: string // 기본 주소 (검색 결과에서 첫줄에 나오는 주소, 검색어의 타입(지번/도로명)에 따라 달라짐).
  addressEnglish: string // 기본 영문 주소.
  addressType: 'R' | 'J' // 검색된 기본 주소 타입: R(도로명), J(지번).
  userSelectedType: 'R' | 'J' // 검색 결과에서 사용자가 선택한 주소의 타입.
  noSelected: 'Y' | 'N' // 연관 주소에서 "선택 안함" 부분을 선택했을 때를 구분할 수 있는 상태변수.
  userLanguageType: 'K' | 'E' // 검색 결과에서 사용자가 선택한 주소의 언어 타입: K(한글주소), E(영문주소).
  roadAddress: string // 도로명 주소 (지번:도로명 주소가 1:N인 경우에는 데이터가 공백으로 들어갈 수 있음).
  roadAddressEnglish: string // 영문 도로명 주소.
  jibunAddress: string // 지번 주소 (도로명:지번 주소가 1:N인 경우에는 데이터가 공백으로 들어갈 수 있음).
  jibunAddressEnglish: string // 영문 지번 주소.
  autoRoadAddress: string // 지번주소에 매핑된 도로명주소가 여러 개인 경우, 사용자가 '선택안함' 또는 '지번주소'를 클릭했을 때 연관된 도로명 주소 중 임의로 첫 번째 매핑 주소를 넣어서 반환합니다.
  autoRoadAddressEnglish: string // autoRoadAddress의 영문 도로명 주소.
  autoJibunAddress: string // 도로명주소에 매핑된 지번주소가 여러 개인 경우, 사용자가 '선택안함' 또는 '도로명주소'를 클릭했을 때 연관된 지번 주소 중 임의로 첫 번째 매핑 주소를 넣어서 반환합니다.
  autoJibunAddressEnglish: string // autoJibunAddress의 영문 지번 주소.
  buildingCode: string // 건물관리번호.
  buildingName: string // 건물명.
  apartment: 'Y' | 'N' // 공동주택 여부: 아파트, 연립주택, 다세대주택 등.
  sido: string // 도/시 이름.
  sidoEnglish: string // 도/시 이름의 영문.
  sigungu: string // 시/군/구 이름.
  sigunguEnglish: string // 시/군/구 이름의 영문.
  sigunguCode: string // 시/군/구 코드 (5자리 구성된 시/군/구 코드).
  roadnameCode: string // 도로명 코드, 7자리로 구성된 도로명 코드입니다. 추후 7자리 이상으로 늘어날 수 있습니다.
  bcode: string // 법정동/법정리 코드.
  roadname: string // 도로명 값, 검색 결과 중 선택한 도로명주소의 "도로명" 값이 들어갑니다 (건물번호 제외).
  roadnameEnglish: string // 도로명 값, 검색 결과 중 선택한 도로명주소의 "도로명의 영문" 값이 들어갑니다 (건물번호 제외).
  bname: string // 법정동/법정리 이름.
  bnameEnglish: string // 법정동/법정리 이름의 영문.
  bname1: string // 법정리의 읍/면 이름 ("동" 지역일 경우에는 공백, "리" 지역일 경우에는 "읍" 또는 "면" 정보가 들어갑니다).
  bname1English: string // 법정리의 읍/면 이름의 영문 ("동" 지역일 경우에는 공백, "리" 지역일 경우에는 "읍" 또는 "면" 정보가 들어갑니다).
  bname2: string // 법정동/법정리 이름.
  bname2English: string // 법정동/법정리 이름의 영문.
  hname: string // 행정동 이름, 검색어를 행정동으로 검색하고, 검색결과의 법정동과 검색어에 입력한 행정동과 다른 경우에 표시.
  query: string // 사용자가 입력한 검색어.
}

export const usePostcodeSearch = () => {
  const [data, setData] = useState<DaumPostcodeData | null>(null)

  const open = useDaumPostcodePopup(DAUM_POSTCODE_URL)

  const handleComplete = (data: DaumPostcodeData) => {
    setData(data)
  }

  const handleOpenPopup = () => {
    open({ onComplete: handleComplete })
  }

  return {
    data,
    handleOpenPopup,
  }
}
