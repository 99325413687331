import {
  Badge,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  Textarea,
} from '@/components'
import { useParams } from 'react-router-dom';
import { Comments } from '../comment/comments'
import { BoardHeader } from '../_components/boardHeader'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { useAxios } from '@/context/axiosContext'
import { ChangeEvent, useEffect, useId, useRef, useState } from 'react'
import { useAppSelector } from '@/redux/hooks'
import { BoardListItem, CommentListItem } from '../boardItem'
import useCopyToClipboard from '@/hooks/useCopyToClipboard'
import { useMaskIp } from '@/hooks/useMaskIp'
import { useBadge, useHeaderTitle } from '@/hooks/useBoard'

type TInput = HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement

interface Data {
  cmpnyCd: string
  nttSn: number
}

const DetailBoard: React.FC = () => {
  const axios = useAxios()
  const getBadgeLabel = useBadge()
  const getMaskIp = useMaskIp('lastTwo')
  const navigate = useNavigate()
  const headerTitle = useHeaderTitle()
  const { pathname } = useLocation()
  const pathName = pathname.split('/')[1] // 예: '/type1/list' -> 'type1'
  const [isCopied, copyToClipboard] = useCopyToClipboard()
  const { isAuthenticated, user } = useAppSelector((state) => state.auth)
  const commentRef = useRef<HTMLDivElement>(null)

  //isAuthenticated true
  //use.id 아이디 가져오는거
  // Modal 상태
  const [show, setShow] = useState(false)
  const [modalMessage, setModalMessage] = useState('') // Modal 메시지 상태
  const [boardDetail, setBoardDetail] = useState<BoardListItem | null>(null)
  const [isDisabled, setIsDisabled] = useState(true) // 초기값은 비활성화
  const [searchParams] = useSearchParams() // 쿼리 파라미터 사용
  //const {  pathName} = useParams();
  const nttSn = Number(searchParams.get('NTT_SN')) // 게시판 번호
  const cmpnyCd = '001'

  const [commentList, setCommentList] = useState<CommentListItem[]>([])
  const [commentListCnt, setCommentListCnt] = useState(0)
  const [modalType, setModalType] = useState('')
  const [buttonType, setButtonType] = useState('')
  const [height, setHeight] = useState('auto'); // 상태로 높이를 관리
  const userId = user?.userId

  useEffect(() => {
    // 초기 렌더링 시 텍스트 영역의 높이를 자동으로 맞추기 위해 한 번 실행
    if (boardDetail?.NTT_SJ) {
      setHeight('auto');
      setHeight(`${document.getElementById('nttSj')?.scrollHeight}px`);
    }
  }, [boardDetail?.NTT_SJ]);

  const handleInputChange = (e: ChangeEvent<HTMLTextAreaElement>)  => {
    // 자동으로 텍스트 영역의 높이 조정
    setHeight('auto'); // 먼저 높이를 'auto'로 리셋
    setHeight(`${e.target.scrollHeight}px`); // 실제 내용에 맞는 높이 설정
    handleChange(e); // 부모 컴포넌트에서 받아온 변경 핸들러 호출
  };

  useEffect(() => {
    if (nttSn || pathName) {
      getBoardDetail() // nttSn 값이 있을 때 API 호출
      getCommentList()
    }
  }, [nttSn, pathName])

  const handleCopy = () => {
    const url = window.location.href // 현재 페이지 URL
    copyToClipboard(url)
    console.log('handleCopy', url)
  }

  const handleChange = (e: React.ChangeEvent<TInput>) => {
    const { name, value } = e.target

    setBoardDetail((prevState) => {
      if (!prevState) return null

      return {
        ...prevState,
        [name]: value,
      } as BoardListItem // 모든 필드를 포함하는 상태를 유지
    })
  }

  console.log('boardDetail::', boardDetail)

  //detailView
  const getBoardDetail = async () => {
    try {
      const data: Data = {
        cmpnyCd: cmpnyCd,
        nttSn: nttSn,
      }

      if (!cmpnyCd || !nttSn) {
        console.error('필수 파라미터가 없습니다.')
        return
      }

      const response = await axios.get(`/${pathName}/detail`, {
        headers: { 'Cache-Control': 'no-cache' },
        params: { ...data },
      })

      const { boardDetail } = response.data
      setBoardDetail(boardDetail)
      // 응답 데이터가 배열인지 확인하고 상태 업데이트
    } catch (err: any) {
      console.error('Error fetching boardDetail:', err)
    }
  }

  const getCommentList = async () => {
    try {
      const data: Data = {
        cmpnyCd: cmpnyCd,
        nttSn: nttSn,
      }

      const response = await axios.get(`/${pathName}/comment/list`, {
        params: { ...data },
      })

      const { commentList } = response.data

      console.log('commentListCnt', response.data)
      console.log('commentListCnt', commentListCnt)
      console.log('commentList', commentList)
      if (Array.isArray(commentList)) {
        setCommentList(commentList)
        setCommentListCnt(commentList?.length)
      }

      console.log('commentList', commentList?.length)
    } catch (err: any) {
      console.error('Error fetching commentList:', err)
    }
  }

  const handleShowModal = (type: 'update' | 'delete' | 'cancel' | 'save') => {
    setModalType(type)
    setModalMessage(
      type === 'save'
        ? '저장하시겠습니까?'
        : type === 'delete'
          ? '삭제하시겠습니까?'
          : '현재 작업이 저장되지 않았습니다. 그래도 취소하시겠습니까?',
    )
    setShow(true)
  }

  const handleModalAction = () => {
    if (modalType === 'delete') {
      deleteBoard()
    } else if (modalType === 'cancel') {
      setTimeout(() => {
        navigate(`/${pathName}`) // 500ms 후에 페이지 이동
      }, 500)
    } else if (modalType === 'save') {
      updateBoard()
    }
    setShow(false)
  }

  const updateBoard = async () => {
    console.log('updateBoard')

    try {
      const updatedData = {
        cmpnyCd: cmpnyCd,
        nttSn: nttSn,
        nttSj: boardDetail?.NTT_SJ, // 수정된 제목
        nttCn: boardDetail?.NTT_CN, // 수정된 내용
        useYn: 'Y',
      }

      console.log('updatedData', updatedData)
      const response = await axios.post(`/${pathName}/update`, updatedData, {
        headers: {
          'Content-Type': 'application/json',
        },
      })

      console.log('Updated board response:', response)

      setTimeout(() => {
        navigate(`/${pathName}`) // 500ms 후에 페이지 이동
      }, 500)

      // 수정이 완료되면, 상태를 초기화하거나, 적절한 처리를 진행
    } catch (err: any) {
      console.error('Error updating board:', err)
    }
  }

  const deleteBoard = async () => {
    try {
      const data: Data = {
        cmpnyCd: cmpnyCd,
        nttSn: nttSn,
      }

      if (!cmpnyCd || !nttSn) {
        console.error('필수 파라미터가 없습니다.')
        return
      }

      const response = await axios.post(`/${pathName}/delete`, data, {
        headers: {
          'Content-Type': 'application/json', // JSON 형식으로 데이터를 보내도록 명시
        },
      })

      setTimeout(() => {
        navigate(`/${pathName}`) // 500ms 후에 페이지 이동
      }, 500)

      console.log('response', response)
    } catch (err: any) {
      console.error('Error fetching deleteBoard:', err)
    }
  }

  const handleEditMode = () => {
    setIsDisabled(false)

    console.log('isDisabled', isDisabled)
  }

  const scrollToComment = () => {
    commentRef.current?.scrollIntoView({
      behavior: 'smooth', // 부드러운 스크롤
    })
  }

  const textareaRef = useRef<HTMLTextAreaElement>(null)

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto' // 높이 초기화
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px` // 내용에 따라 높이 조정
    }
  }, [boardDetail?.NTT_CN])

  return (
    <>
      <BoardHeader title={headerTitle} />
      <div
        style={{
          marginBottom: '10px',
          width: '100%',
          height: 'auto',
          padding: '29px 29px 0',
          borderRadius: '6px',
          border: '1px solid',
          borderColor: '#ebecef',
        }}
      >
        <Badge color="secondary">
          {getBadgeLabel(boardDetail?.BBS_SJ || '기타')}
        </Badge>
      {isDisabled ? (
        // 수정 불가 모드일 때는 그냥 텍스트로 보여주기
        <div
          style={{
            fontSize: '20px',
            fontWeight: '600',
            whiteSpace: 'pre-wrap', // 자동 줄바꿈
            wordWrap: 'break-word', // 긴 단어 줄바꿈
            overflow: 'hidden',
            marginBottom:'5px',
            marginTop:'5px'
          }}
        >
        {boardDetail?.NTT_SJ}
        </div>
      ) : (
        // 수정 모드일 때는 textarea로 표시
        <textarea
          id="nttSj"
          name="NTT_SJ"
          style={{
            border: 'none',
            padding: '0',
            margin: '0', 
            fontSize: '20px',
            backgroundColor: 'transparent',
            wordWrap: 'break-word', // 긴 단어 줄바꿈
            whiteSpace: 'normal', // 자동 줄바꿈 허용
            lineHeight: '1.5', // 줄 간격 설정
            display: 'block', // block 요소로 설정
            width: '100%', // 부모 요소 너비에 맞추기
            resize: 'none', // 크기 조정 버튼 제거
            height: height, // 동적으로 높이를 설정
            overflow: 'hidden', // 스크롤바 제거
          }}
          value={boardDetail?.NTT_SJ}
          disabled={isDisabled}
          onChange={handleInputChange}
        />
      )}
        <div style={{ color: '#979797' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div>
              <div>
                작성자 : {boardDetail?.NICK_NAME} (
                {getMaskIp(boardDetail?.ACCESS_IP || '')})
              </div>
              <div style={{ display: 'flex' }}>
                <div>작성일 : {boardDetail?.REG_DATE}</div>
                <div
                  style={{
                    margin: '0 8px',
                    borderLeft: '1px solid #ddd',
                    height: '16px',
                  }}
                />
                <div>조회 : {boardDetail?.READNG_CNT}</div>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
              }}
            >
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path>
                </svg>
              </div>
              <div
                style={{ marginLeft: '8px', cursor: 'pointer' }}
                onClick={scrollToComment}
              >
                댓글 {boardDetail?.ANSWER_CNT}
              </div>
              <div
                style={{
                  margin: '0 8px',
                  borderLeft: '1px solid #ddd',
                  height: '16px',
                }}
              />

              <div
                style={{ marginLeft: '8px', cursor: 'pointer' }}
                onClick={handleCopy}
              >
                URL복사
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            boxShadow: '0 0 0 0.5px #ebecef',
            borderColor: '#ebecef',
            marginTop: '10px',
            marginBottom: '10px',
          }}
        />
        <div
          style={{
            marginBottom: '10px',
            border: 'none',
            height: 'auto',
            borderRadius: '4px',
            padding: '2.5em 0',
          }}
        >
          <textarea
            ref={textareaRef}
            id="nttCn"
            name="NTT_CN"
            value={boardDetail?.NTT_CN}
            disabled={isDisabled}
            onChange={handleChange}
            style={{
              width: '100%',
              height: '800px',
              padding: '0 0 3.75em 0',
              border: 'none', // 테두리 제거
              outline: 'none', // 포커스 시 테두리 제거
              borderRadius: '4px', // 모서리를 둥글게
              resize: 'none', // 사용자가 크기 조정 불가
              overflow: 'hidden', // 스크롤 숨기기
              backgroundColor: 'transparent',
            }}
          />
        </div>
        <div
          style={{
            marginBottom: '20px',
          }}
        />
      </div>
      <div ref={commentRef} style={{ display: 'flex', marginBottom: '10px' }}>
        <div style={{ marginLeft: 'auto' }}>
          {isDisabled ? (
            <div style={{ display: 'flex', gap: '5px' }}>
              {userId === boardDetail?.REG_ID && (
                <>
                  <Button color="neverbe" onClick={handleEditMode}>
                    수정
                  </Button>
                  <Button
                    color="neverbe"
                    onClick={() => handleShowModal('delete')}
                  >
                    삭제
                  </Button>
                </>
              )}
              <Button color="neverbe" onClick={() => navigate(`/${pathName}`)}>
                목록
              </Button>
            </div>
          ) : (
            <div style={{ display: 'flex', gap: '5px' }}>
              <Button color="neverbe" onClick={() => handleShowModal('save')}>
                저장
              </Button>
              <Button color="neverbe" onClick={() => handleShowModal('cancel')}>
                취소
              </Button>
              <Button color="neverbe" onClick={() => navigate(`/${pathName}`)}>
                목록
              </Button>
            </div>
          )}
        </div>
      </div>
      <Comments
        commentListCnt={commentListCnt}
        nttSn={nttSn}
        commentList={commentList}
        pathName={pathName}
      />
      <Modal show={show} onClose={() => setShow(false)} size="sm">
        <ModalBody>{modalMessage}</ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={handleModalAction}>
            {modalType === 'save'
              ? '수정'
              : modalType === 'delete'
                ? '삭제'
                : '목록으로 이동'}
          </Button>
          <Button color="secondary" onClick={() => setShow(false)}>
            닫기
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default DetailBoard
