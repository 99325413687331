import SignupForm from '@/components/member/signup-form'
import {
  StyledImage,
  StyledImgText,
  StyledMedia,
  StyledMediaBody,
  StyledSignin,
} from '@/components/member/signup/style'
import styled from 'styled-components'

const NewMember = () => {
  return (
    // <StyledMedia>
    //   <StyledSignin>
    <SignupFormWrapper>
      <SignupForm />
    </SignupFormWrapper>
    //   </StyledSignin>
    // </StyledMedia>
  )
}

const SignupFormWrapper = styled.div`
  padding: 0 200px;
`
export default NewMember
