import React, {
  createContext,
  ReactNode,
  useState,
  useContext,
  useEffect,
} from 'react'
import { useLocation } from 'react-router-dom'

const defaultSEO = {
  title: 'NeverBe Golf',
  description:
    'Neverbe Golf. 네버비 골프에서 골프 관련 서비스를 확인하세요. neverbe golf, golf neverbe',
  canonicalUrl: 'https://golf.neverbe.co.kr',
}

const SEOContext = createContext<{
  seoSettings: typeof defaultSEO
  setSeoSettings: React.Dispatch<React.SetStateAction<typeof defaultSEO>>
}>({
  seoSettings: defaultSEO,
  setSeoSettings: () => {},
})

export const SEOProvider = ({ children }: { children: ReactNode }) => {
  const [seoSettings, setSeoSettings] = useState(defaultSEO)
  const location = useLocation()

  useEffect(() => {
    setSeoSettings(defaultSEO)
  }, [location])

  return (
    <SEOContext.Provider value={{ seoSettings, setSeoSettings }}>
      {children}
    </SEOContext.Provider>
  )
}

export const useSEO = () => useContext(SEOContext)
