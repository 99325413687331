import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

const ResultPage = () => {
  const [data, setData] = useState<string | null>(null)
  const location = useLocation()

  useEffect(() => {
    // URL에서 token 파라미터를 추출
    const params = new URLSearchParams(location.search)
    const token = params.get('result')

    if (params) {
      setData(token)
    }
  }, [location])

  return (
    <div>
      {data ? <p>인증 성공! : {data}</p> : <p>인증 결과를 처리하는 중...</p>}
    </div>
  )
}

export default ResultPage
