import React from 'react'
import { Button } from '@/components'
import { useAxios } from '@/context/axiosContext'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import { logout } from '@/redux/slices/auth'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import LogoutIcon from '@mui/icons-material/Logout'

const AuthContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`

const HeaderAuth: React.FC = () => {
  const axios = useAxios()
  const { isAuthenticated, user } = useAppSelector((state) => state.auth)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const handleLogout = async () => {
    try {
      await axios.post('/auth/logout')
      dispatch(logout())
      navigate('/')
    } catch (error) {
      console.error('로그아웃 실패:', error)
      alert('로그아웃에 실패했습니다. 다시 시도해주세요.')
    }
  }

  return (
    <AuthContainer>
      {isAuthenticated ? (
        <>
          <span style={{ fontWeight: 'bold' }}>
            {user?.name ?? '사용자'}님 환영합니다.
          </span>
          <Button
            height="38px"
            borderRadius="24px"
            color="white"
            onClick={handleLogout}
          >
            로그아웃
            <LogoutIcon
              style={{ marginLeft: '2px', width: '16px', height: '16px' }}
            />
          </Button>
        </>
      ) : (
        <>
          <Button
            onClick={() => navigate('/login')}
            borderRadius="24px"
            color="white"
          >
            <span style={{ color: '#333333', fontWeight: 'bold' }}>로그인</span>
          </Button>
          <Button
            onClick={() => navigate('/member')}
            borderRadius="24px"
            color="neverbe"
          >
            회원가입
          </Button>
        </>
      )}
    </AuthContainer>
  )
}

export default HeaderAuth
