import ListGroupItem from '@/components/ui/list-group/list-group-item'
import Table from '@/components/ui/table/table'
import React from 'react'
import Textarea from '@/components/forms/form-elements/textarea'
import Checkbox from '@/components/forms/form-elements/checkbox'
import Button from '@/components/ui/button/button'
import { buttonStyle, left } from 'styled-system'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAxios } from '@/context/axiosContext'

const Member: React.FC = () => {
  const navigate = useNavigate()
  const isLogin = true
  const axios = useAxios()
  const [termsResponse, setTermsResponse] = useState<any>(null)
  const [termsResponse2, setTermsResponse2] = useState<any>(null)
  const [termsResponse3, setTermsResponse3] = useState<any>(null)

  const [checkedItems, setCheckedItems] = useState({
    memberCheck_1: false,
    memberCheck_2: false,
    memberCheck_3: false,
  })

  useEffect(() => {
    sendTermsGetData()
  }, [])

  // 약관 정보 체크 GET 요청
  const sendTermsGetData = async () => {
    try {
      const response = await axios.get('/agree/selectTermsMasterList', {
        params: {},
      }) // GET 요청

      //console.log('약관 : ' + JSON.stringify(response, null))

      setTermsResponse(response.data[0].termsCont) // 서버에서 받은 응답을 상태에 저장
      setTermsResponse2(response.data[1].termsCont) // 서버에서 받은 응답을 상태에 저장
      setTermsResponse3(response.data[2].termsCont) // 서버에서 받은 응답을 상태에 저장
    } catch (error) {
      console.error('Error during GET request:', error)
    }
  }

  const buttonHandler = (pathName: string) => {
    //체크박스 체크여부 확인
    if ('agree' == pathName) {
      const allChecked = Object.values(checkedItems).every(
        (isChecked) => isChecked,
      )

      if (!allChecked) {
        alert('모든 체크박스가 체크되지 않았습니다.')
        return
      }
    }

    if (isLogin) {
      window.scrollTo(0, 0) // 페이지 상단으로 이동

      switch (pathName) {
        case 'agree':
          return navigate('/member/newMember')
        case 'cancle':
          return navigate('/main')
        default:
          return navigate('/member')
      }
    }
  }

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target
    setCheckedItems((prevState) => ({
      ...prevState,
      [name]: checked,
    }))
  }

  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h1 style={{ textAlign: 'left' }}>네버비 회원가입</h1>
      <p style={{ textAlign: 'left' }}>회원가입 -약관동의</p>
      <Table>
        <thead>
          <tr>
            <th scope="col">&nbsp;</th>
          </tr>
        </thead>
        <tbody style={{ textAlign: 'left' }}>
          <h2 style={{ textAlign: 'left' }}>이용약관</h2>
          <Textarea
            id="memberArea_1"
            name="memberArea_1"
            value={termsResponse}
          />
          <br />
          <Checkbox
            id="memberCheck_1"
            name="memberCheck_1"
            label="동의합니다."
            checked={checkedItems.memberCheck_1}
            onChange={handleCheckboxChange}
          />
          <br />
          <br />
          <h2 style={{ textAlign: 'left' }}>개인 정보 취급 방침</h2>
          <Textarea
            id="memberArea_2"
            name="memberArea_2"
            value={termsResponse2}
          />
          <br />
          <Checkbox
            id="memberCheck_2"
            name="memberCheck_2"
            label="동의합니다."
            checked={checkedItems.memberCheck_2}
            onChange={handleCheckboxChange}
          />
          <br />
          <br />
          <h2 style={{ textAlign: 'left' }}>개인정보 취급 위탁 동의서</h2>
          <Textarea
            id="memberArea_3"
            name="memberArea_3"
            value={termsResponse3}
          />
          <br />
          <Checkbox
            id="memberCheck_3"
            name="memberCheck_3"
            label="동의합니다."
            checked={checkedItems.memberCheck_3}
            onChange={handleCheckboxChange}
          />
          <br />
          <br />
          <br />
          <br />
          <div style={{ textAlign: 'center' }}>
            <Button color="neverbe" onClick={() => buttonHandler('agree')}>
              동의합니다.
            </Button>
            &nbsp;
            <Button
              color="neverbe"
              variant="outlined"
              onClick={() => buttonHandler('cancle')}
            >
              동의하지 않습니다.
            </Button>
          </div>
        </tbody>
      </Table>
    </div>
  )
}

export default Member
