import Layout from '@/components/common/layout'
import Loading from '@/components/common/loading'
import { SEO } from '@/components/seo/seo'
import AppRoutes from '@/routes/AppRoutes'
import { BrowserRouter as Router } from 'react-router-dom'

function App() {
  return (
    <Router>
      <Loading />
      <Layout>
        <SEO />
        <AppRoutes />
      </Layout>
    </Router>
  )
}

export default App
