import Button, { ButtonProps } from '../button/button'

const DropdownToggle = ({
  label = 'DropdownToggle',
  className = 'dropdown-toggle',
  ...props
}: ButtonProps) => {
  const { children, ...restProps } = props
  return <Button {...restProps}>{children}</Button>
}

DropdownToggle.displayName = 'DropdownToggle'

export default DropdownToggle
