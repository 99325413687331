import ErrorContainer from '@/components/error/error-404'
import { SigninContainer } from '@/components/main/signin/signin'
import AboutUs from '@/pages/about/aboutUs'
import Board from '@/pages/board/board'
import DetailBoard from '@/pages/board/detail/detailBoard'
import InsertBoard from '@/pages/board/insert/insertBoard'
import Main from '@/pages/main/main'
import NewMember from '@/pages/member/agree/newMember'
import CompleteMember from '@/pages/member/completeMember/completeMember'
import Member from '@/pages/member/member'
import NewMember2 from '@/pages/member/newMember/newMember'
import Notice from '@/pages/notice/notice'
import ResultPage from '@/pages/sample/result/resultPage'
import Sample from '@/pages/sample/sample'
import { RouteObject } from 'react-router-dom'

type RouteConfig = RouteObject & {
  label?: string
  hideHeader?: boolean
  hideFooter?: boolean
  isFullWidth?: boolean
}

export const routes: RouteConfig[] = [
  {
    path: '/',
    element: <Main />,
  },
  {
    path: '/main',
    element: <Main />,
    label: '홈',
  },
  {
    path: '/board',
    element: <Board />,
    label: '문의게시판',
  },
  {
    path: '/community',
    element: <Board />,
    label: '커뮤니티',
  },
  {
    path: '/sample',
    element: <Sample />,
    label: '',
  },
  {
    path: '/sample/result',
    element: <ResultPage />,
    label: '',
  },
  {
    path: '/member',
    element: <Member />,
    label: '',
  },
  {
    path: '/member/agree',
    element: <NewMember />,
  },
  {
    path: '/member/newMember',
    element: <NewMember2 />,
  },
  {
    path: '/member/completeMember',
    element: <CompleteMember />,
  },
  {
    path: '/board/write',
    element: <InsertBoard />,
  },
  {
    path: '/community/write',
    element: <InsertBoard />,
  },
  {
    path: '/board/detail',
    element: <DetailBoard />,
  },
  {
    path: '/community/detail',
    element: <DetailBoard />,
  },
  {
    path: '/login',
    element: <SigninContainer />,
    hideHeader: false,
    hideFooter: false,
    isFullWidth: true,
  },
  {
    path: '/about',
    element: <AboutUs />,
    label: '서비스소개',
  },
  {
    path: '/notice',
    element: <Notice />,
    label: '공지사항',
  },
  {
    path: '*',
    element: <ErrorContainer />,
    label: '',
  },
]
