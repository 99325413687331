import {
  Button,
  InputGroup,
  InputGroupAddon,
  Textarea,
  Modal,
  ModalBody,
} from '@/components'
import { useAxios } from '@/context/axiosContext'
import { useAppSelector } from '@/redux/hooks'
import { Ref, useEffect, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import ModalFooter from '@/components/ui/modal/modal-header'
import { CommentsProps } from '../boardItem'

type TInput = HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
type ModalAction = 'insert' | 'delete' | 'update'

interface Data {
  cmpnyCd: string
  nttSn: number
  commentSn: number
  option: string
}

export const Comments = (props: CommentsProps) => {
  const axios = useAxios()
  const navigate = useNavigate()
  const { isAuthenticated, user } = useAppSelector((state) => state.auth)
  const [isDisabled, setIsDisabled] = useState(true) // 초기값은 비활성화
  console.log('commentList::', props.commentList)
  const [formData, setFormData] = useState({
    cmpnyCd: '001',
    nttSn: props.nttSn,
    commentCn: '',
    cstId: user?.cstId, //고객번호 (우선하드코딩) 로그인데이터를 가져오도록 수정필요
    userId: user?.userId, //userId
  })

  const [modalShow, setModalShow] = useState(false)
  const [modalMessage, setModalMessage] = useState('')
  const [modalButton, setModalButton] = useState('')
  const [error, setError] = useState<string | null>(null)
  const [searchParams] = useSearchParams()
  const nttSn = Number(searchParams.get('NTT_SN')) // 게시판 번호
  const cmpnyCd = '001'

  const [selectedItem, setSelectedItem] = useState<any | null>(null) // 선택된 댓글 item

  const handleChange = (e: React.ChangeEvent<TInput>) => {
    const { name, value } = e.target as HTMLInputElement | HTMLTextAreaElement
    setFormData((prev) => ({ ...prev, [name]: value }))
  }

  // 공통 처리 함수
  const handleCommentAction = (action: ModalAction, item?: any) => {
    console.log("handleCommentAction")
    // 선택된 item이 있다면 해당 item의 COMMENT_SN을 저장
    setSelectedItem(item)
    console.log("commentSn", item)
    console.log("selectedItem", selectedItem)

    if (action === 'insert' && !formData.commentCn) {
      setModalMessage('내용을 입력하세요.')
      setModalShow(true)
      return
    }

    let message = ''
    let buttonText = ''
    switch (action) {
      case 'insert':
        message = '저장하시겠습니까?'
        buttonText = '저장'
        break
      case 'update':
        message = '수정하시겠습니까?'
        buttonText = '수정'

        // **선택된 댓글의 내용을 formData에 설정**
        // if (item) {
        //   setFormData((prev) => ({
        //     ...prev,
        //     commentCn: item.COMMENT_CN, // 선택된 댓글 내용으로 업데이트
        //   }))
        // }

        break
      case 'delete':
        message = '삭제하시겠습니까?'
        buttonText = '삭제'
        break
      default:
        return
    }

    // 모달 설정
    setModalMessage(message)
    setModalButton(buttonText)
    setModalShow(true)
  }

  const insertComment = async () => {
    try {
      const response = await axios.post(`/${props.pathName}/comment/insert`, formData)
      console.log('insertComment:', response.data)
      navigate(0)
    } catch (err: any) {
      console.error('Error insertComment:', err)
    }
  }

  // const updateComment = async (commentSn: number) => {
  //   try {
  //     const response = await axios.post(`/${props.pathName}/comment/`, {
  //       commentSn,
  //       commentCn: formData.commentCn, // 업데이트할 댓글 내용 전달
  //     })
  //     console.log('updateComment:', response.data)
  //     navigate(0)
  //   } catch (err: any) {
  //     console.error('Error updateComment:', err)
  //   }
  // }

  const deleteComment = async () => {

    alert("deleteComment")
    const data: Data = {
      cmpnyCd: cmpnyCd,
      nttSn: nttSn,
      commentSn: selectedItem,
      option: 'DEL',
    }

    try {
      alert("deleteComment_try")
      const response = await axios.post(`/${props.pathName}/comment/delete`, data)
      console.log('deleteComment:', response.data)
      navigate(0)
    } catch (err: any) {
      console.error('Error deleteComment :', err)
    }
  }

  return (
    <>
      {props.commentListCnt > 0 && <div style={{ marginBottom: '10px' }}>댓글 [{props.commentListCnt}]</div>}
      {isAuthenticated && (
      <div style={{ marginBottom: '10px' }}>
        <div
          style={{
            padding: '10px',
            border: '1px solid #c0ccda',
            height: 'auto',
            borderRadius: '4px',
            marginBottom: '10px',
          }}
        >
          <div style={{ fontWeight: 'bold' }}>{user?.nickName}</div>
          <div
            style={{ display: 'flex', justifyContent: 'space-between' }}
          />
          <textarea
            id="commentCn"
            name="commentCn"
            value={formData.commentCn || ''}
            onChange={handleChange}
            onInput={(e) => {
              const target = e.target as HTMLTextAreaElement
              target.style.height = 'auto' // 높이를 초기화하여 스크롤 높이를 정확히 계산
              target.style.height = `${target.scrollHeight}px` // 내용에 맞게 높이 설정
            }}
            style={{
              width: '100%',
              padding: '10px',
              border: 'none', // 테두리 제거
              outline: 'none', // 포커스 시 테두리 제거
              borderRadius: '4px', // 모서리를 둥글게
              resize: 'none', // 사용자가 크기 조정 불가
              overflow: 'hidden', // 스크롤 숨기기
            }}
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'right',
              color: '#808080',
              cursor: 'pointer',
            }}
          >
            <div
              onClick={
                () => handleCommentAction('insert') // item을 전달하여 선택된 댓글 정보를 저장
              }
            >
              댓글쓰기
            </div>
          </div>
        </div>
      </div>
      )}

      <Modal show={modalShow} onClose={() => setModalShow(false)} size="sm">
        <ModalBody>{modalMessage}</ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            onClick={() => {
              if (modalButton === '저장') {
                insertComment()
              } else if (modalButton === '삭제') {
                alert("삭제!!")
                console.log("selectedItem", selectedItem)
                if (selectedItem) {
                  deleteComment() // 선택된 아이템의 COMMENT_SN을 전달
                }
              }
              setModalShow(false)
            }}
          >
            {modalButton}
          </Button>
          <Button color="secondary" onClick={() => setModalShow(false)}>
            취소
          </Button>
        </ModalFooter>
      </Modal>

      {props.commentList.map((item) => (
        <div
          key={item.COMMENT_SN}
          style={{
            padding: '10px',
            border: '1px solid #c0ccda',
            height: 'auto',
            borderRadius: '4px',
            marginBottom: '10px',
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ fontWeight: 'bold' }}>{item.NICK_NAME}</div>
            <div>{item.REG_DATE}</div>
          </div>
          <div
            style={{
              padding: '10px',
              height: 'auto',
              border: 'none', // 테두리 제거
              outline: 'none', // 포커스 시 테두리 제거
            }}
          >
            {item.COMMENT_CN}
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'right',
              color: '#808080',
              cursor: 'pointer',
            }}
          >
            {isAuthenticated && user?.cstId === item.CST_ID && (
              <div
                onClick={
                  () => handleCommentAction('delete', item.COMMENT_SN) // item을 전달하여 선택된 댓글 정보를 저장
                }
              >
                삭제
              </div>
            )}
          </div>
        </div>
      ))}
    </>
  )
}
