import ListGroupItem from '@/components/ui/list-group/list-group-item'
import Table from '@/components/ui/table/table'
import React from 'react'
import Textarea from '@/components/forms/form-elements/textarea'
import Input from '@/components/forms/form-elements/input'
import Label from '@/components/forms/form-elements/label'
import Checkbox from '@/components/forms/form-elements/checkbox'
import Button from '@/components/ui/button/button'
import { buttonStyle, left } from 'styled-system'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

const NewMember: React.FC = () => {
  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h1 style={{ textAlign: 'left' }}>네버비 회원가입</h1>
      <p style={{ textAlign: 'left' }}>회원가입 -약관동의</p>

      <div style={{ textAlign: 'left' }}>
        <Label htmlFor={'id'}>
          아이디
          <Input
            id="id"
            name="id"
            placeholder="Enter your id"
            value={''}
          ></Input>
        </Label>
        <br />
        <br />
        <Label htmlFor={'password'}>
          비밀번호
          <Input
            id="password"
            name="password"
            placeholder="Enter your password"
            value={''}
          ></Input>
        </Label>
      </div>
    </div>
  )
}

export default NewMember
