import { CSSProperties } from 'react'
import { StyledFeedback } from './style'

export interface IFeedback {
  children: React.ReactNode
  state?: 'success' | 'warning' | 'error'
  showState?: boolean
  showErrorOnly?: boolean
  feedbackTextAlign?: CSSProperties['textAlign']
}

const Feedback = ({
  state,
  showState,
  showErrorOnly,
  feedbackTextAlign,
  children,
}: IFeedback) => {
  return (
    <StyledFeedback
      $state={state}
      $showState={showState}
      $showErrorOnly={showErrorOnly}
      $feedbackTextAlign={feedbackTextAlign}
    >
      {children}
    </StyledFeedback>
  )
}

export default Feedback
