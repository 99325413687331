import { Anchor } from '@/components'
import FormGroup from '@/components/forms/form-elements/form-group'
import Input from '@/components/forms/form-elements/input'
import {
  IFormValues,
  useLogin,
} from '@/components/main/signin/signin-form/useLogin'

import { SubmitHandler, useForm } from 'react-hook-form'
import {
  StyledBottomText,
  StyledButton,
  StyledDesc,
  StyledTitle,
  StyledWrap,
} from './style'
import commonUtils from '@/utils/commonUtils'

const SigninForm = () => {
  const { handleLogin } = useLogin()

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormValues>()

  const onSubmit: SubmitHandler<IFormValues> = (data) => {
    handleLogin(data)
  }

  return (
    <StyledWrap>
      <StyledTitle>
        대한민국 <span className="highlight">골프</span> 정보 플랫폼
      </StyledTitle>
      <StyledDesc>네버비에 오신 것을 환영합니다!</StyledDesc>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <FormGroup mb="16px">
          <Input
            type="text"
            id="userId"
            placeholder="아이디 입력"
            feedbackText={errors?.userId?.message}
            state={commonUtils.hasKey(errors, 'userId') ? 'error' : 'success'}
            showState={!!commonUtils.hasKey(errors, 'userId')}
            {...register('userId', {
              required: '아이디를 입력하세요.',
            })}
            height="56px"
          />
        </FormGroup>
        <FormGroup mb="16px">
          <Input
            id="password"
            type="password"
            placeholder="비밀번호 입력"
            feedbackText={errors?.password?.message}
            state={commonUtils.hasKey(errors, 'password') ? 'error' : 'success'}
            showState={!!commonUtils.hasKey(errors, 'password')}
            {...register('password', {
              required: '비밀번호를 입력하세요.',
            })}
            height="56px"
          />
        </FormGroup>
        <StyledButton type="submit">로그인</StyledButton>
        <StyledBottomText>
          <Anchor path="/member">아이디/비밀번호 찾기</Anchor>
          <Anchor path="/member">회원가입</Anchor>
        </StyledBottomText>
      </form>
    </StyledWrap>
  )
}

export default SigninForm
