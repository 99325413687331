import styled from 'styled-components'

import { Badge, DataTable } from '@/components'
import { MainGolfSearchNoData } from '@/components/main/golfSearch/mainGolfSearchNoData'
import { tableStyles } from '@/components/main/golfSearch/style'
import { useGolfSearchData } from '@/components/main/golfSearch/useGolfSearchData'
import numberUtils from '@/utils/numberUtils'
import { useEffect } from 'react'
import { TableColumn } from 'react-data-table-component'
import { useNavigate } from 'react-router-dom'

interface DataRow {
  bizCd: string
  bizNm: string
  areaNm: string
  n01: string
  n02: string
  m01: string
  m02: string
  mshipYn?: string | null
}

export const MainGolfSearchResult = () => {
  const { data } = useGolfSearchData()
  const navigate = useNavigate()
  const goDetail = (row: DataRow) => {
    // navigate('/golf/golfDetail?bizCd =' + row.bizCd)
  }

  const columns: TableColumn<DataRow>[] = [
    {
      name: '골프장',
      selector: (row) => row.bizNm,
      sortable: true,
      cell: (row) => (
        <div onClick={() => goDetail(row)} style={{ cursor: 'pointer' }}>
          {row.mshipYn === 'Y' ? (
            <Badge color="success" shape="circle">
              회
            </Badge>
          ) : row.mshipYn === 'N' ? (
            <Badge color="info" shape="circle">
              비
            </Badge>
          ) : null}
          &nbsp;{row.bizNm}
        </div>
      ),
    },
    { name: '지역', selector: (row) => row.areaNm, sortable: true },
    {
      name: '비회원(평일)',
      selector: (row) => row.n01,
      sortable: true,
      cell: (row) =>
        typeof row.n01 === 'number' && row.n01 === 0
          ? '갱신중'
          : numberUtils.formatNumberWithCommas(row.n01) || '-',
    },
    {
      name: '비회원(주말)',
      selector: (row) => row.n02,
      sortable: true,
      cell: (row) =>
        typeof row.n02 === 'number' && row.n02 === 0
          ? '갱신중'
          : numberUtils.formatNumberWithCommas(row.n02) || '-',
    },
    {
      name: '회원(평일)',
      selector: (row) => row.m01,
      sortable: true,
      cell: (row) =>
        typeof row.m01 === 'number' && row.m01 === 0
          ? '갱신중'
          : numberUtils.formatNumberWithCommas(row.m01) || '-',
    },
    {
      name: '회원(주말)',
      selector: (row) => row.m02,
      sortable: true,
      cell: (row) =>
        typeof row.m02 === 'number' && row.m02 === 0
          ? '갱신중'
          : numberUtils.formatNumberWithCommas(row.m02) || '-',
    },
  ]

  useEffect(() => {
    const sortableHeaders = document.querySelectorAll(
      '.rdt_TableCol_Sortable span',
    )

    sortableHeaders.forEach((header) => {
      const spanElement = header as HTMLElement
      spanElement.style.opacity = '1'
    })
  }, [data])

  return (
    <MainGolfSearchContainerWrapper>
      <BadgeWrapper>
        <span>
          <Badge color="success" shape="circle">
            회
          </Badge>
          &nbsp;: 회원제
        </span>
        <span>
          <Badge color="info" shape="circle">
            비
          </Badge>
          &nbsp;: 비회원제
        </span>
      </BadgeWrapper>
      <DataTable
        // theme={theme}
        columns={columns}
        data={data}
        customStyles={tableStyles}
        noDataComponent={<MainGolfSearchNoData />}
      />
      <TotalCount>
        총 <b>{data.length}</b> 건
      </TotalCount>
    </MainGolfSearchContainerWrapper>
  )
}

const MainGolfSearchContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 400px;
`

const BadgeWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-bottom: 10px;
`
const TotalCount = styled.p`
  margin-left: auto;
  margin-right: 10px;
  margin-top: 10px;
`
