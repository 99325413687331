import styled, { themeGet } from '@/styles/styled'
import { Button } from '@/components'

export const StyledWrap = styled.div`
  width: 100%;
  max-width: 560px;
  margin: 0 auto;
  padding: 40px 16px;
  text-align: center;

  @media (max-width: 480px) {
    padding: 24px 12px;
  }
`

export const StyledTitle = styled.h3`
  font-size: 24px;
  font-weight: bold;
  color: ${themeGet('colors.text')};
  margin-bottom: 10px;

  .highlight {
    color: ${themeGet('colors.neverbe')};
  }
`

export const StyledDesc = styled.p`
  font-size: 16px;
  color: ${themeGet('colors.text3')};
  margin-bottom: 32px;

  @media (max-width: 480px) {
    font-size: 14px;
    margin-bottom: 24px;
  }
`

export const StyledButton = styled(Button)`
  width: 100%;
  height: 56px;
  background-color: ${themeGet('colors.neverbe')};
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  border-radius: 6px;
  border: none;

  &:hover,
  &:focus {
    background-color: ${themeGet('colors.neverbe')};
  }
`

export const StyledBottomText = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  gap: 16px;
  font-size: 13px;

  a {
    color: black;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`

export const StyledBottomDivider = styled.div`
  width: 1px;
  background-color: ${themeGet('colors.light')};
  height: 16px;
  margin: auto 8px;
`
