import { useEffect, useState } from 'react'
import { GoMoveToTop } from 'react-icons/go'
import styled from 'styled-components'

const ScrollTopButtonWrapper = styled.div`
  position: fixed;
  bottom: 50px;
  right: 15%;

  @media (max-width: 768px) {
    right: 2%;
  }

  z-index: 20;
`

const ScrollTopButton = () => {
  const [showTopButton, setShowTopButton] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      setShowTopButton(window.scrollY > 200)
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  return showTopButton ? (
    <ScrollTopButtonWrapper>
      <GoMoveToTop
        style={{
          width: '30px',
          height: '30px',
          cursor: 'pointer',
          zIndex: 1000,
        }}
        onClick={scrollToTop}
      />
    </ScrollTopButtonWrapper>
  ) : null
}

export default ScrollTopButton
