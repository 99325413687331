import React, { useEffect, useState } from 'react'
import { useAxios } from '@/context/axiosContext'

interface PostData {
  tmp1: string
  tmp2: string
  tmp3: number
}

interface GetData {
  param: number
}

const AxiosSample: React.FC = () => {
  const axios = useAxios()
  const [getData, setGetData] = useState<any>(null)
  const [postResponse, setPostResponse] = useState<any>(null)
  // GET 요청
  const sendGetData = async () => {
    try {
      const tmp_data: GetData = {
        param: 1,
      }
      const response = await axios.get('/sample', { params: { ...tmp_data } }) // GET 요청
      setGetData(response.data) // 서버에서 받은 데이터를 상태에 저장
    } catch (error) {
      console.error('Error during GET request:', error)
    }
  }

  // POST 요청
  const sendPostData = async () => {
    const data: PostData = {
      tmp1: 'this is tmp1',
      tmp2: 'this is tmp2',
      tmp3: 99,
    }

    try {
      const response = await axios.post('/post-endpoint', data) // POST 요청
      setPostResponse(response.data) // 서버에서 받은 응답을 상태에 저장
    } catch (error) {
      console.error('Error during POST request:', error)
    }
  }

  // useEffect(() => {
  //   fetchGetData(); // 컴포넌트 마운트 시 GET 요청 실행
  // }, []);

  return (
    <div>
      {/* POST 요청 버튼 */}
      <button onClick={sendGetData}>Send GET Request</button>

      {/* GET 결과 출력 */}
      <h2>GET Response:</h2>
      {getData ? (
        <pre>{JSON.stringify(getData, null, 2)}</pre>
      ) : (
        <p>Loading GET data...</p>
      )}

      {/* POST 요청 버튼 */}
      <button onClick={sendPostData}>Send POST Request</button>

      {/* POST 결과 출력 */}
      <h2>POST Response:</h2>
      {postResponse ? (
        <pre>{JSON.stringify(postResponse, null, 2)}</pre>
      ) : (
        <p>No POST response yet.</p>
      )}
    </div>
  )
}

export default AxiosSample
