import PretendardRegular from './Pretendard-Regular.woff2'
import PretendardBold from './Pretendard-Bold.woff2'

export const Pretendard = `
  @font-face {
    font-family: 'Pretendard';
    src: url(${PretendardRegular}) format('woff2');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'Pretendard';
    src: url(${PretendardBold}) format('woff2');
    font-weight: 700;
    font-style: normal;
  }
`
