import { useSEO } from '@/context/seoContext'
import { Helmet } from 'react-helmet-async'
import defaultOgImage from '@/assets/images/neverbe.jpg'

type SEOProps = {
  title?: string
  description?: string
  canonicalUrl?: string
  noIndex?: boolean
}

type SEOSettings = {
  title?: string
  description?: string
  canonicalUrl?: string
  ogImage?: string
}

export const SEO = ({
  title,
  description,
  canonicalUrl,
  noIndex = false,
}: SEOProps) => {
  const { seoSettings }: { seoSettings: SEOSettings } = useSEO()

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{title || seoSettings.title}</title>
      <meta
        name="description"
        content={
          description ||
          seoSettings.description ||
          'Neverbe Golf. 네버비 골프에서 골프 관련 서비스를 확인하세요. neverbe golf, golf neverbe'
        }
      />
      {noIndex && <meta name="robots" content="noindex, nofollow" />}
      <link
        rel="canonical"
        href={
          canonicalUrl ||
          seoSettings.canonicalUrl ||
          'https://golf.neverbe.co.kr'
        }
      />

      {/* Open Graph */}
      <meta property="og:title" content={title || seoSettings.title} />
      <meta
        property="og:description"
        content={
          description ||
          seoSettings.description ||
          'Neverbe Golf. 네버비 골프에서 골프 관련 서비스를 확인하세요. neverbe golf, golf neverbe'
        }
      />
      <meta
        property="og:url"
        content={
          canonicalUrl ||
          seoSettings.canonicalUrl ||
          'https://golf.neverbe.co.kr'
        }
      />
      <meta property="og:type" content="website" />
      <meta
        property="og:image"
        content={seoSettings.ogImage || defaultOgImage}
      />
    </Helmet>
  )
}
