import { useCallback } from 'react'

export const useMaskIp = (
  maskingType: 'lastTwo' | 'fullNetwork' | 'customRange',
  startIndex = 2,
  endIndex = 3,
) => {
  const maskIp = useCallback(
    (ip: string) => {
      const ipParts = ip.split('.')

      if (ipParts.length !== 4) {
        return ip // 유효하지 않은 IP 형식은 그대로 반환
      }

      switch (maskingType) {
        case 'lastTwo': {
          // 마지막 두 부분 마스킹
          ipParts[2] = '***'
          ipParts[3] = '***'
          break
        }
        case 'fullNetwork': {
          // 전체 네트워크 대역 마스킹
          ipParts[1] = '***'
          ipParts[2] = '***'
          ipParts[3] = '***'
          break
        }
        case 'customRange': {
          // 특정 범위만 마스킹
          for (let i = startIndex; i <= endIndex; i++) {
            if (ipParts[i]) ipParts[i] = '***'
          }
          break
        }
        default:
          break
      }

      return ipParts.join('.')
    },
    [maskingType, startIndex, endIndex],
  )

  return maskIp
}
