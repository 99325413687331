import App from '@/App'
import { AxiosProvider } from '@/context/axiosContext'
import { LoadingProvider, useLoading } from '@/context/loadingContext'
import '@/index.css'
import PersistProvider from '@/redux/providers/persist-provider'
import ThemeProvider from '@/redux/providers/theme-provider'
import { store } from '@/redux/store'
import reportWebVitals from '@/reportWebVitals'
import { setupAxiosInterceptors } from '@/utils/axiosInstance'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { HelmetProvider } from 'react-helmet-async'
import { Provider } from 'react-redux'

const Root = () => {
  const { startLoading, stopLoading } = useLoading()
  setupAxiosInterceptors(startLoading, stopLoading)

  return <App />
}

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistProvider>
        <ThemeProvider>
          <LoadingProvider>
            <AxiosProvider>
              <HelmetProvider>
                <Root />
              </HelmetProvider>
            </AxiosProvider>
          </LoadingProvider>
        </ThemeProvider>
      </PersistProvider>
    </Provider>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
