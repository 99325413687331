import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface GolfSearchState {
  bizNm?: string | null
  minPrice?: number | null
  maxPrice?: number | null
  areaCd?: string | null
  dayType?: string | null
  months?: number | null
}

interface GraphDataState {
  graphData: number[][] | []
}

const initialSearchState: GolfSearchState = {
  bizNm: null,
  minPrice: null,
  maxPrice: null,
  areaCd: null,
  dayType: null,
  months: null,
}

const initialGraphState: GraphDataState = {
  graphData: [],
}

const golfSearchSlice = createSlice({
  name: 'golfSearch',
  initialState: {
    searchParams: initialSearchState,
    graphData: initialGraphState,
  },
  reducers: {
    setSearchParams(state, action: PayloadAction<GolfSearchState>) {
      state.searchParams = action.payload
    },
    clearSearchFilters(state) {
      state.searchParams = initialSearchState
    },
    setGraphData(state, action: PayloadAction<number[][]>) {
      state.graphData.graphData = action.payload
    },
    clearGraphData(state) {
      state.graphData.graphData = []
    },
  },
})

export const {
  setSearchParams,
  clearSearchFilters,
  setGraphData,
  clearGraphData,
} = golfSearchSlice.actions

export default golfSearchSlice.reducer
