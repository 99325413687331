import React, { createContext, useContext, useState, useRef } from 'react'

type LoadingContextType = {
  isLoading: boolean
  startLoading: () => void
  stopLoading: () => void
}

const LoadingContext = createContext<LoadingContextType | undefined>(undefined)

export const LoadingProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const loadingStartTime = useRef<number | null>(null)

  const startLoading = () => {
    if (!isLoading) {
      setIsLoading(true)
      loadingStartTime.current = Date.now()
    }
  }

  const stopLoading = () => {
    const minimumDuration = 500 // 최소 유지 시간 (0.5초)
    const elapsedTime = loadingStartTime.current
      ? Date.now() - loadingStartTime.current
      : 0

    const remainingTime = Math.max(minimumDuration - elapsedTime, 0)

    setTimeout(() => {
      setIsLoading(false)
      loadingStartTime.current = null
    }, remainingTime) // 최소 유지 시간에 맞게 대기 후 종료
  }

  return (
    <LoadingContext.Provider value={{ isLoading, startLoading, stopLoading }}>
      {children}
    </LoadingContext.Provider>
  )
}

export const useLoading = () => {
  const context = useContext(LoadingContext)
  if (!context) {
    throw new Error('useLoading must be used within a LoadingProvider')
  }
  return context
}
