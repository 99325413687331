import { useState } from 'react'

interface PaginationProps {
  totalCount: number
  onPageChange: (page: number) => void // 타입 정의
}

const Pagination: React.FC<PaginationProps> = ({
  totalCount,
  onPageChange,
}) => {
  const perPage = 10 //한페이지에 보여줄 항목 수
  const [currentPage, setCurrentPage] = useState(1)

  //페이지 데이터 계산
  const startIndex = (currentPage - 1) * perPage

  // 페이지 수 계산
  const totalPage = Math.ceil(totalCount / perPage)

  const handlePageClick = (page: number) => {
    if (page >= 1 && page <= totalCount) {
      setCurrentPage(page)
      onPageChange(page) // 페이지 변경 콜백
    }
  }

  return (
    <div style={{ position: 'relative' }}>
      <div style={styles.pagination}>
        {/* Previous button */}
        <button
          style={styles.button}
          onClick={() => handlePageClick(currentPage - 1)}
          disabled={currentPage === 1}
        >
          «
        </button>

        {Array.from({ length: totalPage }, (_, index) => (
          <button
            key={index + 1}
            style={{
              ...styles.button,
              ...(currentPage === index + 1 ? styles.activeButton : {}),
            }}
            onClick={() => handlePageClick(index + 1)}
          >
            {index + 1}
          </button>
        ))}

        <button
          style={styles.button}
          onClick={() => handlePageClick(currentPage + 1)}
          disabled={currentPage === totalPage}
        >
          »
        </button>
      </div>
    </div>
  )
}

const styles = {
  pagination: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    marginTop: '20px',
  },
  button: {
    padding: '10px 15px',
    fontSize: '16px',
    border: '1px solid #ddd',
    borderRadius: '5px',
    backgroundColor: 'white',
    cursor: 'pointer',
    transition: 'all 0.3s',
  },
  activeButton: {
    backgroundColor: '#d09d30',
    color: 'white',
    fontWeight: 'bold',
  },
}

export default Pagination
