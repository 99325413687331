import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { routes } from '@/routes/routes'
import styled from 'styled-components'
import { FaBars, FaTimes } from 'react-icons/fa'

const GNBContainer = styled.nav`
  display: flex;
  gap: 20px;

  @media (max-width: 768px) {
    display: none;
  }
`

const MenuItem = styled(Link)`
  text-decoration: none;
  color: #333333;
  font-size: 1rem;
  font-weight: 600;
  transition: color 0.3s ease;

  &:hover {
    color: #ff7043;
  }
`

const MobileMenuButton = styled.button`
  display: none;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #333;

  @media (max-width: 768px) {
    display: block;
    position: relative;
    z-index: 110;
  }
`

const MobileMenuOverlay = styled.div<{ isOpen: boolean }>`
  display: ${(props) => (props.isOpen ? 'flex' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 100;

  a {
    color: #ffffff;
    font-size: 1.25rem;
    font-weight: bold;
    margin: 15px 0;
    text-transform: uppercase;
    letter-spacing: 1px;
    transition: color 0.3s ease;

    &:hover {
      color: #ff7043;
    }
  }
`

const CloseButton = styled(FaTimes)`
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 1.5rem;
  color: #ffffff;
  cursor: pointer;
  z-index: 110;
`

const GNB: React.FC = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false)

  const toggleMobileMenu = () => {
    setMobileMenuOpen((prev) => !prev)
  }

  return (
    <>
      <MobileMenuButton onClick={toggleMobileMenu}>
        {isMobileMenuOpen ? <FaTimes /> : <FaBars />}
      </MobileMenuButton>

      <MobileMenuOverlay isOpen={isMobileMenuOpen}>
        <CloseButton onClick={toggleMobileMenu} />
        {routes
          .filter((route) => route.label)
          .map((route, index) => (
            <MenuItem
              key={index}
              to={route.path ?? '/'}
              onClick={() => setMobileMenuOpen(false)}
            >
              {route.label}
            </MenuItem>
          ))}
      </MobileMenuOverlay>

      <GNBContainer>
        {routes
          .filter((route) => route.label)
          .map((route, index) => (
            <MenuItem
              key={index}
              to={route.path ?? '/'}
              onClick={() => setMobileMenuOpen(false)}
            >
              {route.label}
            </MenuItem>
          ))}
      </GNBContainer>
    </>
  )
}

export default GNB
