import { ApexBarChart } from '@/components'
import { ConfirmModal } from '@/components/main/graph/modal/confirmModal'
import { useAppSelector } from '@/redux/hooks'
import { RootState } from '@/redux/store'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

export const MainGraph = () => {
  const { graphData } = useSelector(
    (state: RootState) => state.golfSearch.graphData,
  )
  const { isAuthenticated } = useAppSelector((state) => state.auth)

  const [showCreateModal, setShowCreateModal] = useState(false)

  const safeGraphData = graphData && graphData.length ? graphData : []

  const ApexBarData = {
    options: {
      chart: {
        type: 'bar',
        height: 350,
        toolbar: { show: false },
        stacked: false,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded',
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        curve: 'smooth',
      },
      markers: {
        size: 5,
        strokeWidth: 2,
        hover: {
          size: 7,
        },
        shape: 'circle',
        showNullDataPoints: false,
      },
      xaxis: {
        categories: safeGraphData.length
          ? safeGraphData[0]?.map((_, idx) => {
              const currentMonth = new Date()
              currentMonth.setMonth(currentMonth.getMonth() - (11 - idx))
              return `${currentMonth.getFullYear().toString().slice(-2)}-${(
                currentMonth.getMonth() + 1
              )
                .toString()
                .padStart(2, '0')}`
            })
          : [],
      },
      yaxis: {
        title: { text: '단위 (원)' },
        labels: {
          formatter: (value: any) => value.toLocaleString('ko-KR'),
        },
      },
      fill: {
        opacity: 1,
      },
    },
    series: [
      {
        name: '비회원(평일)',
        type: 'line',
        data: safeGraphData[0]?.map((value) =>
          value === 0 ? null : value,
        ) || [0],
      },
      {
        name: '비회원(주말)',
        type: 'line',
        data: safeGraphData[1]?.map((value) =>
          value === 0 ? null : value,
        ) || [0],
      },
      {
        name: '회원(평일)',
        type: 'bar',
        data: safeGraphData[2] || [0],
      },
      {
        name: '회원(주말)',
        type: 'bar',
        data: safeGraphData[3] || [0],
      },
    ],
  }

  const handleLoginCheck = () => {
    if (!isAuthenticated) {
      setShowCreateModal(true)
    }
  }

  return (
    <>
      <GraphWrapper onClick={handleLoginCheck}>
        <GraphTitle>월 평균 금액</GraphTitle>
        {safeGraphData.length > 0 && (
          <ApexBarChart
            options={ApexBarData.options}
            series={ApexBarData.series}
            height="350px"
            width="100%"
          />
        )}
      </GraphWrapper>
      <ConfirmModal
        show={showCreateModal}
        onClose={() => setShowCreateModal(false)}
      />
    </>
  )
}

const GraphWrapper = styled.div`
  width: 100%;
`

const GraphTitle = styled.p`
  // text-align: center;
  font-size: 18px;
  font-weight: 600;
  margin: 0;
`
