import React from 'react'
import { FaFacebookF, FaInstagram, FaYoutube } from 'react-icons/fa'
import styled from 'styled-components'

const FooterWrapper = styled.footer`
  width: 100%;
  background-color: #f6f7f9;
  border-top: 1px solid #e0e0e0;
  padding: 40px 20px;
`

const FooterContent = styled.div`
  max-width: 60%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
`

const FooterInfo = styled.div`
  font-size: 0.8125rem;
  color: #636773;
  line-height: 1.5;
  white-space: pre-wrap;
  word-wrap: break-word;

  .info-line {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    width: 100%;
    gap: 5px; // 간격 조정

    span {
      white-space: nowrap;
    }

    span:not(:last-child)::after {
      content: '|'; // 파이프라인을 :after로 처리
      margin: 0 5px;
    }
  }

  .address {
    word-break: break-word;
  }

  @media (max-width: 768px) {
    .info-line {
      flex-direction: column;
      align-items: flex-start;
      text-align: left;
    }

    .address {
      text-align: left; // 모바일에서 주소도 왼쪽 정렬
    }

    .info-line span:not(:last-child)::after {
      display: none; // 모바일에서 파이프라인 숨기기
    }
  }
`

const FooterCopyright = styled.div`
  font-size: 0.875rem;
  color: #999999;
  text-align: center;
  margin-top: 20px;
`

const SocialIcons = styled.div`
  display: flex;
  gap: 15px;

  a {
    color: #636773;
    font-size: 1.5rem;
    transition: color 0.3s;

    &:hover {
      color: #333333;
    }
  }

  @media (max-width: 768px) {
    justify-content: flex-start;
    width: 100%;
  }
`

const Footer: React.FC = () => {
  return (
    <FooterWrapper>
      <FooterContent>
        <FooterInfo>
          주식회사 네버비(NEVER BE)
          <br />
          <div className="address">
            (07325) 서울특별시 영등포구 의사당대로 83, 여의도동 18층
            디디18-110-엔(여의도동, 오투타워)
          </div>
          <div className="info-line">
            <span>대표 : 이승철</span>
            <span>사업자번호 : 895-86-01610</span>
            <span>Email : neverbe.family@gmail.com</span>
          </div>
        </FooterInfo>
        <SocialIcons>
          <a
            href="https://www.instagram.com"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Instagram"
          >
            <FaInstagram />
          </a>
          <a
            href="https://www.facebook.com"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Facebook"
          >
            <FaFacebookF />
          </a>
          <a
            href="https://www.youtube.com"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="YouTube"
          >
            <FaYoutube />
          </a>
        </SocialIcons>
      </FooterContent>
      <FooterCopyright>
        Copyright © 2025 NEVERBE Corp. All Rights Reserved.
      </FooterCopyright>
    </FooterWrapper>
  )
}

export default Footer
