import '@/styles/sample/swiper.css'
import { useState } from 'react'
import styled from 'styled-components'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { Navigation, Pagination } from 'swiper/modules'
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react'

export interface BannerSwiperProps {
  bannerList: Array<BannerInfo>
}

interface BannerInfo {
  id: string
  title: string
  imgUrl?: string
  url?: string
}

const BannerSwiper = ({ bannerList }: BannerSwiperProps) => {
  const [swiperInstance, setSwiperInstance] = useState<SwiperClass | null>(null)

  const handleBannerClick = (url?: string) => {
    if (url) {
      window.open(url, '_blank')
    }
  }

  const handlePrev = () => {
    swiperInstance?.slidePrev()
  }

  const handleNext = () => {
    swiperInstance?.slideNext()
  }

  return (
    <BannerSwiperBox>
      <Swiper
        onSwiper={(swiper: SwiperClass) => setSwiperInstance(swiper)}
        slidesPerView={1}
        autoplay={{
          delay: 4500,
          disableOnInteraction: false,
        }}
        speed={500}
        spaceBetween={30}
        loop={true}
        pagination={{
          clickable: true,
        }}
        navigation={false}
        modules={[Pagination, Navigation]}
      >
        {bannerList &&
          bannerList.map((banner: BannerInfo) => (
            <SwiperSlide key={banner.id}>
              <BannerItem onClick={() => handleBannerClick(banner.url)}>
                {/* <img
                  src={`images/banner/${banner.imgUrl}`}
                  alt={banner.title}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'fill',
                    objectPosition: 'center',
                  }}
                /> */}
                {banner.imgUrl ? (
                  <img
                    src={`images/banner/${banner.imgUrl}`}
                    alt={banner.title}
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'fill',
                      objectPosition: 'center',
                    }}
                  />
                ) : (
                  <div
                    style={{
                      width: '100%',
                      height: '280px',
                      backgroundColor: '#f0f0f0', // 공백 배경색
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontSize: '16px',
                      color: '#aaa',
                    }}
                  ></div>
                )}
              </BannerItem>
            </SwiperSlide>
          ))}
      </Swiper>

      <CustomNavigation>
        <CustomPrevButton onClick={handlePrev} />
        <CustomNextButton onClick={handleNext} />
      </CustomNavigation>
    </BannerSwiperBox>
  )
}

const BannerSwiperBox = styled.div`
  width: 100%;
  height: 100%;
  max-height: 280px;
  overflow: hidden;
  position: relative;
  border-radius: 15px;

  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: fill;
    object-position: center;
  }

  .swiper-pagination-bullet {
    background-color: #ccc;
    opacity: 1;
  }

  .swiper-pagination-bullet-active {
    background-color: #000;
  }
`

const BannerItem = styled.div`
  cursor: pointer;
  width: 100%;
  height: 100%;
`

const CustomNavigation = styled.div`
  position: absolute;
  top: 50%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  transform: translateY(-50%);
  z-index: 10;
`

const CustomPrevButton = styled.button`
  all: unset;
  background-image: url('/svg/right-button.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  width: 44px;
  height: 44px;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  transform: rotate(180deg);
`

const CustomNextButton = styled.button`
  all: unset;
  background-image: url('/svg/right-button.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  width: 44px;
  height: 44px;
  cursor: pointer;
  &:focus {
    outline: none;
  }
`

export default BannerSwiper
