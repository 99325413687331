import Input from '@/components/forms/form-elements/input'
import Textarea from '@/components/forms/form-elements/textarea'
import {
  FormGroup,
  InputGroup,
  InputGroupAddon,
  Label,
  Modal,
  ModalBody,
  Select,
} from '@/components'
import { BoardHeader } from '../_components/boardHeader'
import { useAxios } from '@/context/axiosContext'
import { useRef, useState } from 'react'
import Button from '@/components/ui/button/button'
import { useLocation, useNavigate } from 'react-router-dom'
import ModalFooter from '@/components/ui/modal/modal-header'
import { useAppSelector } from '@/redux/hooks'
import { InsertForm } from './insertForm'
import { useHeaderTitle } from '@/hooks/useBoard'

type TInput = HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement

export const InsertMember: React.FC = () => {
  const axios = useAxios()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const pathName = pathname.split('/')[1] // 예: '/type1/list' -> 'type1'
  const headerTitle = useHeaderTitle()
  const { isAuthenticated, user } = useAppSelector((state) => state.auth)
  const [postResponse, setPostResponse] = useState<any>(null)
  const [loading, setLoading] = useState(false) // 로딩 상태
  const [error, setError] = useState<string | null>(null) // 에러 상태
  const [showSaveButton, setShowSaveButton] = useState(true) // true: 저장/취소 || false : 닫기
  const [moveList, setMoveList] = useState(false) // true: 저장/취소 || false : 닫기
  // Modal 상태
  const [show, setShow] = useState(false)
  const [modalMessage, setModalMessage] = useState('') // Modal 메시지 상태

  const [formData, setFormData] = useState({
    cmpnyCd: '001',
    bbsTy: '01',
    nttSj: '',
    nttCn: '',
    bbsSj: '',
    cstId: user?.cstId,
    userId: user?.userId
  })
  const fileInputRef = useRef<HTMLInputElement | null>(null)
  const [fileName, setFileName] = useState<string>('') // 선택된 파일 이름을 저장

  console.log('formData', { formData })
  console.log('inputBoard', user?.cstId, user?.userId)
  //input 값 변경 핸들러
  const handleChange = (e: React.ChangeEvent<TInput>) => {
    const { name, value } = e.target as HTMLInputElement | HTMLTextAreaElement // 타입 캐스팅
    setFormData((prev) => ({ ...prev, [name]: value }))
  }

  //폼 제출 핸들러
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    if (!formData.nttSj || !formData.nttCn) {
      setModalMessage('내용을 입력하세요.')
      setShow(true) // 모달 표시
      setShowSaveButton(false)
      return
    }

    setLoading(true)
    setError(null)

    setModalMessage('저장하시겠습니까?')
    setShow(true)
    setShowSaveButton(true)
  }

  const handleModalAction = async (action: 'confirm' | 'cancel') => {
    if (action === 'confirm') {
      setShow(false)
      setLoading(true) // 로딩 시작

      if (!formData.nttSj || !formData.nttCn) {
        setModalMessage('내용을 입력하세요.')
        setShow(true) // 모달 표시
        setShowSaveButton(false)
        return
      }

      try {
        const response = await axios.post(`/${pathName}/insert`, formData) // POST 요청
        setPostResponse(response.data) // 서버에서 받은 응답을 상태에 저장
        console.log('서버 응답:', response.data)
        console.log('formData:', { formData })
      } catch (err: any) {
        setError(
          err.response?.data?.message || '알 수 없는 오류가 발생했습니다.',
        )
      } finally {
        setLoading(false) // 로딩 종료
      }
    } else if (action === 'cancel') {
      setShow(false) // 모달 닫기
    }
  }

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click() // 숨겨진 파일 입력창 열기
    }
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file) {
      setFileName(file.name) // 선택된 파일 이름 저장
    }
  }

  const handleList = () => {
    setModalMessage('입력을 취소 하시곘습니까?')
    setShow(true)
    setShowSaveButton(true)
    setMoveList(true)
  }

  return (
    <>
      <InsertForm
        formData={formData}
        fileName={fileName}
        pathName={pathName}
        show={show}
        modalMessage={modalMessage}
        showSaveButton={showSaveButton}
        moveList={moveList}
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        handleFileChange={handleFileChange}
        handleButtonClick={handleButtonClick}
        handleModalAction={handleModalAction}
        handleList={handleList}
        setShow={setShow}
        navigate={navigate}
        isAuthenticated={isAuthenticated}
      />
    </>
  )
}
