import BannerSwiper from '@/components/main/banner/bannerSwiper'
import MainBanner from '@/components/main/banner/mainBanner'
import { MainBoard } from '@/components/main/board/mainBoard'
import { MainGolfSearch } from '@/components/main/golfSearch/mainGolfSearch'
import { MainGraph } from '@/components/main/graph/mainGraph'
import { clearSearchFilters } from '@/redux/slices/golfSearchSlice'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'

const Main: React.FC = () => {
  const dispatch = useDispatch()
  const location = useLocation()

  useEffect(() => {
    if (location.pathname === '/main') {
      dispatch(clearSearchFilters())
    }
  }, [location, dispatch])

  return (
    <MainWrapper>
      {/* <SideBannerWrapper position="left">
        <MainBanner
          link="https://admin.neverbe.co.kr"
          imageUrl="/images/membership_neverbe.png"
        />
      </SideBannerWrapper> */}

      <MainContent>
        <BannerSwiper
          bannerList={[
            {
              id: '1',
              title: '하나투어',
              //imgUrl: '/images/membership_neverbe.png',
              //url: 'https://www.hanatour.com/',
            },
            {
              id: '2',
              title: '캘러웨이',
              //imgUrl: 'main_banner_02.png',
              //url: 'https://kr.callawaygolf.com/',
            },
            // {
            //   id: '3',
            //   title: '티스테이션',
            //   //imgUrl: 'main_banner_03.png',
            //   //url: 'https://www.tstation.com/',
            // },
          ]}
        />
        <MainGolfSearch />
        <ContentWrapper>
          <MainGraph />
          <MainBoard />
        </ContentWrapper>
      </MainContent>

      <SideBannerWrapper position="right">
        {/* <MainBanner
          link="https://www.starbucks.co.kr/"
          imageUrl="/images/banner/sample_banner_03.png"
        /> */}
        <div
          style={{
            width: '100%',
            maxWidth: '200px',
            height: '500px',
            margin: '0 15px',
            backgroundColor: '#f0f0f0',
          }}
        ></div>
      </SideBannerWrapper>
    </MainWrapper>
  )
}

const MainWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;
`

const SideBannerWrapper = styled.div<{ position: 'left' | 'right' }>`
  position: fixed;
  top: 0;
  ${(props) => props.position}: 0;
  width: 20%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;

  @media (max-width: 768px) {
    display: none;
  }
`

const MainContent = styled.div`
  width: 100%;
  margin: 0 auto;
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 30px;
  width: 100%;
  margin-top: 50px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
  }
`

export default Main
