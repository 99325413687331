import { Portal } from 'react-portal'
import classnames from 'clsx'
import { CSSTransition } from 'react-transition-group'
import { StyledBackdrop, StyledModal } from './style'
import { IModal } from './types'
import { useRef } from 'react'

const Modal = ({
  className,
  show,
  size = 'md',
  centered = true,
  children,
  onClose,
  closeOnBackdropClick = true,
  ...restProps
}: IModal) => {
  const nodeRef = useRef<HTMLDivElement>(null)
  const handleBackdropClick = () => {
    if (closeOnBackdropClick) {
      onClose()
    }
  }
  return (
    <Portal>
      <>
        <StyledBackdrop $show={show} onClick={handleBackdropClick} />
        <StyledModal
          $show={show}
          $size={size}
          $centered={centered}
          className={classnames(className)}
          onClick={
            !closeOnBackdropClick
              ? (e) => e.stopPropagation()
              : handleBackdropClick
          }
          {...restProps}
        >
          <CSSTransition
            in={show}
            timeout={400}
            unmountOnExit
            classNames="modal"
            nodeRef={nodeRef}
          >
            <div className="modal-dialog">
              <div
                className="modal-content"
                onClick={(e) => e.stopPropagation()}
                onKeyPress={() => {}}
                role="button"
                tabIndex={0}
              >
                {children}
              </div>
            </div>
          </CSSTransition>
        </StyledModal>
      </>
    </Portal>
  )
}

export default Modal
