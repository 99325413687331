import { Button, Modal, ModalBody, Text } from '@/components'
import { ModalStyleWrapper } from '@/components/main/graph/modal/style'
import { useNavigate } from 'react-router-dom'

interface IProps {
  show: boolean
  onClose: () => void
}

export const ConfirmModal = ({ show, onClose }: IProps) => {
  const navigate = useNavigate()

  const handleMoveLogin = () => {
    onClose()
    navigate('/login')
  }

  return (
    <ModalStyleWrapper>
      <Modal show={show} centered onClose={onClose} size="md">
        <ModalBody padding={'20px 0 0 0'}>
          <Text padding={'30px'} fontSize={'16px'} fontWeight={'700'}>
            더 자세한 정보를 확인하려면 로그인이 필요합니다.
            <br /> 로그인 페이지로 이동하시겠습니까?
          </Text>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Button
              borderRadius={'0'}
              onClick={handleMoveLogin}
              width={'100%'}
              height={'56px'}
              color="neverbe"
            >
              확인
            </Button>
            <Button
              borderRadius={'0'}
              width={'100%'}
              color="secondary"
              onClick={onClose}
              size="lg"
            >
              취소
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </ModalStyleWrapper>
  )
}
