import { Badge } from '@/components'
import { StyledTD } from '@/components/main/board/style'
import { StyledTable } from '@/components/ui/table/style'
import { useAxios } from '@/context/axiosContext'
import { useBadge } from '@/hooks/useBoard'
import { BoardListItem } from '@/pages/board/boardItem'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

interface GetBoardData {
  srchOpt?: string //검색옵션
  srchStr?: string //검색어
  page?: number //페이지
  bbsTy: string //게시판타입 (01:자유게시판)
  bbsSj: string //게시판말머리(01:잡담)
}

export const MainBoard = () => {
  const axios = useAxios()
  const navigate = useNavigate()

  const getBadgeLabel = useBadge()

  const [srchData] = useState({
    srchOpt: '',
    srchStr: '',
  })

  const [boardList, setBoardList] = useState<BoardListItem[]>([])

  useEffect(() => {
    getBoardList()
  }, [])

  //boardList
  const getBoardList = async () => {
    try {
      const data: GetBoardData = {
        srchOpt: srchData.srchOpt,
        srchStr: srchData.srchStr,
        page: 1,
        bbsTy: '01',
        bbsSj: '',
      }

      const response = await axios.get('/board/list', {
        params: { ...data },
      })

      const { boardList } = response.data
      if (Array.isArray(boardList)) {
        setBoardList(boardList)
      }
    } catch (err: any) {
      console.error('Error fetching boardList:', err)
    }
  }

  //글상세보기
  const handleDetailView = (nttSn: number, answerCnt: number) => {
    window.scrollTo(0, 0)
    navigate(`/board/detail?NTT_SN=${nttSn}&ANSWER_CNT=${answerCnt}`)
  }

  return (
    <div style={{ width: '100%' }}>
      <BoardTitle>문의게시판</BoardTitle>
      <StyledTable
        style={{ tableLayout: 'fixed', width: '100%', marginTop: '28px' }}
      >
        <tbody>
          {boardList.length > 0 ? (
            boardList.map((item) => (
              <tr key={item.NTT_SN}>
                <StyledTD
                  style={{ width: 'auto' }}
                  onClick={() => handleDetailView(item.NTT_SN, item.ANSWER_CNT)}
                >
                  <Badge color="secondary">{getBadgeLabel(item.BBS_SJ)}</Badge>
                  <span style={{ marginLeft: '10px' }}>{item.NTT_SJ}</span>
                </StyledTD>
                <StyledTD style={{ width: '100px' }}>{item.NICK_NAME}</StyledTD>
              </tr>
            ))
          ) : (
            <tr>
              <StyledTD colSpan={2} style={{ padding: '20px 10px' }}>
                아직 등록된 문의가 없습니다. 첫 번째로 문의를 남겨보세요!
              </StyledTD>
            </tr>
          )}
        </tbody>
      </StyledTable>
    </div>
  )
}

const BoardTitle = styled.p`
  // text-align: center;
  font-size: 18px;
  font-weight: 600;
  margin: 0;
`
