import SigninForm from '@/components/main/signin/signin-form/signinForm'

import Content from '@/components/layout/content'
import { StyledMedia, StyledSignin } from './style'
import { SEO } from '@/components/seo/seo'

export const SigninContainer = () => {
  return (
    <>
      <SEO
        title="로그인 - NeverBe Golf"
        description="Login to NeverBe Golf. 네버비 골프에서 골프 관련 서비스를 확인하세요. 다양한 키워드로 쉽게 검색 가능합니다."
        noIndex={true}
      />
      <Content fullHeight>
        <StyledMedia>
          <StyledSignin>
            <SigninForm />
          </StyledSignin>
        </StyledMedia>
      </Content>
    </>
  )
}
