import Input from '@/components/forms/form-elements/input'
import { SearchFilterModal } from '@/components/main/golfSearch/modal'
import { StyledForm } from '@/components/main/search-form/style'
import Button from '@/components/ui/button/button'
import { setSearchParams } from '@/redux/slices/golfSearchSlice'
import { useCallback, useState } from 'react'
import { Search, X } from 'react-feather'
import { HiAdjustmentsHorizontal } from 'react-icons/hi2'
import { useDispatch } from 'react-redux'

import styled from 'styled-components'

export interface ReqGolfPriceData {
  bizNm?: string | null
  minPrice?: number | null
  maxPrice?: number | null
  areaCd?: string | null
  dayType?: string | null
}

export const MainGolfSearchFilter = () => {
  const dispatch = useDispatch()

  const [showCreateModal, setShowCreateModal] = useState(false)
  const [filters, setFilters] = useState<ReqGolfPriceData>({
    bizNm: null,
    minPrice: null,
    maxPrice: null,
    areaCd: null,
    dayType: 'weekday',
  })

  const updateFilter = (
    key: keyof ReqGolfPriceData,
    value: string | number | null,
  ) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: value,
    }))
  }

  const handleSearchClick = () => {
    dispatch(setSearchParams(filters))
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSearchClick()
    }
  }

  const clearSearch = () => {
    updateFilter('bizNm', null)
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    handleSearchClick()
  }

  const applyFilters = useCallback(
    (newFilters: ReqGolfPriceData) => {
      setFilters((prev) => {
        const updatedFilters = { ...prev, ...newFilters }
        dispatch(setSearchParams(updatedFilters))
        return updatedFilters
      })
    },
    [dispatch],
  )

  return (
    <MainGolfSearchContainerWrapper>
      <StyledForm style={{ width: '50%' }} onSubmit={handleSubmit}>
        <div style={{ position: 'relative', width: '100%' }}>
          <Input
            type="text"
            id="search"
            name="serach"
            placeholder="골프장명을 입력해주세요."
            value={filters.bizNm || ''}
            onChange={(e) => updateFilter('bizNm', e.target.value)}
            onKeyDown={handleKeyDown}
            style={{}}
          />
          {filters.bizNm && (
            <X
              onClick={clearSearch}
              style={{
                position: 'absolute',
                right: '10px',
                top: '50%',
                transform: 'translateY(-50%)',
                cursor: 'pointer',
                width: '20px',
                height: '20px',
              }}
            />
          )}
        </div>
        <Button
          variant="texted"
          iconButton
          size="md"
          onClick={handleSearchClick}
          className="btn"
        >
          <Search onClick={handleSearchClick} />
        </Button>
        <HiAdjustmentsHorizontalWrapper>
          <HiAdjustmentsHorizontal
            onClick={() => setShowCreateModal(true)}
            style={{ width: '30px', height: '30px' }}
          />
        </HiAdjustmentsHorizontalWrapper>
      </StyledForm>
      <SearchFilterModal
        show={showCreateModal}
        onClose={() => setShowCreateModal(false)}
        applyFilters={applyFilters}
      />
    </MainGolfSearchContainerWrapper>
  )
}

const MainGolfSearchContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0;
`

const HiAdjustmentsHorizontalWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
`
