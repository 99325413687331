import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { BoardHeader } from './_components/boardHeader'
import { BoardList } from './list/boardList'
import { useHeaderTitle } from '@/hooks/useBoard'

const Board: React.FC = () => {
  const headerTitle = useHeaderTitle()
  // InsertBoard (부모 컴포넌트)
  //   └ useBoard (공통 로직 - 상태 관리 및 핸들러)
  //   └ BoardForm (공통 UI 컴포넌트)
  //        ├ MemberForm (회원 전용 추가 UI)
  //        ├ GuestForm (비회원 전용 추가 UI)
  return (
    <>
      <BoardHeader title={headerTitle} />
      <BoardList />
    </>
  )
}

export default Board
