import { useAppSelector } from '@/redux/hooks'
import { InsertMember } from './insertMember'
import { InsertGuest } from './insertGuest'
import { BoardHeader } from '../_components/boardHeader'
import { useHeaderTitle } from '@/hooks/useBoard'

const InsertBoard = () => {
  const headerTitle = useHeaderTitle()
  const { isAuthenticated } = useAppSelector((state) => state.auth)

  console.log('headerTitle', headerTitle)

  return (
    <>
      <BoardHeader title={headerTitle} />
      {isAuthenticated ? <InsertMember /> : <InsertGuest />}
    </>
  )
}

export default InsertBoard
