/* eslint-disable @typescript-eslint/no-unused-vars */
import styled, { SpaceProps, TypographyProps, LayoutProps } from 'styles/styled'

interface IProps extends SpaceProps, TypographyProps, LayoutProps {}

export const StyledLabel = styled(
  ({ mb, display, fontSize, fontWeight, ...rest }) => <label {...rest} />,
)<IProps>`
  display: inline-block;
`
