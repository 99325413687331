import clsx from 'clsx'
import { SpaceProps, BorderProps } from 'styles/styled'
import { StyledTableResponsive, StyledTable } from './style'

type TColor =
  | 'primary'
  | 'secondary'
  | 'success'
  | 'danger'
  | 'warning'
  | 'info'
  | 'light'
  | 'dark'

interface IProps extends SpaceProps, BorderProps {
  children: React.ReactNode
  className?: string
  theadColor?: TColor
  striped?: boolean
  bordered?: boolean
  hover?: boolean
  compact?: boolean
  color?: TColor
  borderless?: boolean
  style?: React.CSSProperties
}

const Table = ({
  children,
  className,
  theadColor,
  striped,
  bordered,
  hover,
  compact,
  color,
  borderless,
  style,
  ...restProps
}: IProps) => {
  return (
    <StyledTableResponsive>
      <StyledTable
        className={clsx(className)}
        style={style}
        $theadColor={theadColor}
        $striped={striped}
        $bordered={bordered}
        $hover={hover}
        $compact={compact}
        $color={color}
        $borderless={borderless}
        {...restProps}
      >
        {children}
      </StyledTable>
    </StyledTableResponsive>
  )
}

export default Table
